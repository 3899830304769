import React from "react";

import { useQuery } from "react-query";
import ServiceData from "../Apis/ServiceData";
import "./ServicesCard.css";
import ServicesCard from "./ServicesCard";
const ServicesCardSection = () => {
  let aos_mood = [
    { data: "fead-right", duration: "2000" },
    { data: "fead-up", duration: "2000" },
    { data: "fead-up", duration: "2000" },
    { data: "fead-left", duration: "2000" },
  ];
  const { data, isSuccess } = useQuery("serviceCard", () => ServiceData());
  return (
    <div className="repair_service_card_group flex">
      {isSuccess &&
        data.data.map((item,index) => {
          return (
            <ServicesCard key={item.s_title} title={item.s_title} shortDescription={item.s_short_description} image={item.s_image} classname={index < 3 ? "sm-visible" : ""} />
          );
        })}
    </div>
  );
};
export default ServicesCardSection;
