import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, {useEffect} from "react"
// import PaymentForm from "./PaymentForm"
import StripePayment from "./StripePayment"
import {useCheckoutContext} from '../Context/CheckoutContext';
import { useNavigate } from "react-router-dom"; 

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
function convertPriceToInteger(price) {
    const priceInCents = price * 100;
    const roundedPrice = Math.round(priceInCents);
    return roundedPrice;
}
export default function StripeContainer() {
    const {formData, total, selectedPaymentMethod, selectedShippingMethod} = useCheckoutContext()
    const navigate = useNavigate()
    useEffect(() => {
        if (!total) {
            navigate('/products'); 
        }
    }, [total]);
    let intTotal =  convertPriceToInteger(total)
    const options = {
        mode: 'payment',
        amount: intTotal,
        currency: 'usd',
    };
	return (
		<Elements stripe={stripePromise} options={options}>
			{/* <PaymentForm /> */}
            <StripePayment intTotal={intTotal} formData={formData} selectedPaymentMethod={selectedPaymentMethod} selectedShippingMethod={selectedShippingMethod}/>
		</Elements>
	)
}
