import React, { useEffect, useState } from "react";
import {
    IconMapPinShare,
    IconTool,
} from "@tabler/icons-react";
import Footer from "./component/footer";
import Header from "./component/Header/header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useQuery } from "react-query";
import ProductsData from "./Apis/ProductsData";
import RelatedProduct from "./component/RelatedProduct";
import './Home.css';
import ServicesCardSection from "./component/ServicesCardSection";
import QuickMapViewSection from "./component/MapCard/QuickMapViewSection";
import RepairingProcess from "./component/repairingProcess";
import RepairItemsSection from "./component/RepairItems/RepairItemsSection";
import ThreeDParticleScene from "./component/ThreeDParticleScene";
import ProductListSection from "./component/ProductListSection/ProductListSection";
import { Container } from "react-bootstrap";
import { DealsData } from "./Apis/DealsData";
import SectionHeading from "./component/sectionHeading";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Slider from "./Apis/Slider";
// import { SingleProductList } from "./Apis/SingleProduct";
const Home = () => {
    // const list_of_category_list = [
    //   "Accessories",
    //   "Iphones",
    //   "Androids"
    // ]

    useEffect(() => {
        AOS.init();
    }, []);
    // const [accessories, setAccessories] = useState(false);
    // const [iphone, setIphone] = useState(false);
    // const [androids, setAndroids] = useState(false);
    const { data, isFetched, isSuccess, isLoading, isError } = useQuery("productdata", ProductsData);
    const { data: WeeklyDealsData } = useQuery("WeeklyDealsData", DealsData);
    const { data: SliderData } = useQuery("SliderData", Slider);


    
    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (isError) {
        return <p>Error fetching data</p>;
    }

    if (isSuccess && data && WeeklyDealsData) {
        let iphone_data = data.data.filter(value => value.p_category === "Iphones" && value.p_status !== 0);
        let androids_data = data.data.filter(value => value.p_category === "Androids" && value.p_status !== 0);
        let accessories_data = data.data.filter(value => value.p_category === "Accessories" && value.p_status !== 0);
        let weekly_deals_data = WeeklyDealsData.data.filter(value => value.p_status !== 0 && value.weekly_deal !== 0)

        // let new_product = {data: data.data.slice(0,4)};
        // setAccessories({data: accessories_data})
        // setIphone({data: iphone_data})
        // setAndroids({data: androids_data})
        return (
            <div className="App">
                {/* <BubblePartical id="bubblepertical"></BubblePartical> */}
                <ThreeDParticleScene />
                <Header></Header>
                <div className="hero_area">
                    {/* <ParticlesAnimatiob id="tsparticles"></ParticlesAnimatiob> */}

                    <div className="banner_area flex full_container m-auto">
                        
                    <OwlCarousel
                        className="owl-theme"
                        margin={10}
                        items={1}
                        dots={false}
                        loop={true}
                        responsiveClass
                        nav={false}
                        autoplay={true}
                        autoplayTimeout={5000}
                        autoplayHoverPause={false}
                        slideTransition={`linear`}
                    >   
                        {
                            SliderData.data.map(item =>(
                                item.p_status ?(
                                    <div className="item">
                                        <div className="banner_content flex justify-spece-between">
                                            <div className="text_area">
                                                <h3>{item.heading}</h3>
                                                <h4>
                                                    {item.subheading}
                                                </h4>
                                                <div className="btn_group flex">
                                                    <a href="/contactus" className="link_btn map_view">
                                                        <IconMapPinShare /> Location
                                                    </a>
                                                    <a href="/repairing" className="link_btn repair_page_link">
                                                        <IconTool /> Learn more
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="image_section">
                                                <img src={`${process.env.REACT_APP_FRONTEND_DATA_URL}/${item.p_image}`} alt={item.heading} />
                                            </div>
                                        </div>
                                    </div>

                                ) : null
                                
                            ))
                        }
                    </OwlCarousel>
                        
                    </div>
                </div>
                <section className="content_section full_container flex justify-spece-between">

                    <div className={`product_category ${!weekly_deals_data.length ? "w-100" : ''}`}>
                        <ProductListSection title="Iphones" productList={{ data: iphone_data.slice(0, 4) }}></ProductListSection>
                        <ProductListSection title="Androids" productList={{ data: androids_data.slice(0, 4) }}></ProductListSection>
                        <ProductListSection title="Accessories" productList={{ data: accessories_data.slice(0, 4) }}></ProductListSection>
                        {/* <ProductListSection title="New Arriavals" productList={new_product}></ProductListSection> */}
                    </div>
                    {
                        weekly_deals_data.length ? (
                            <div className="weekly_deal">
                                <div className="text-center mb-20">
                                    <SectionHeading text="Deal of the Week"></SectionHeading>
                                </div>

                                <div className="image_section relative m-auto" style={{ width: "fit-content" }}>
                                    <img className="w-100"
                                        src={`${process.env.REACT_APP_FRONTEND_DATA_URL}/${weekly_deals_data[0].product_image}`}
                                        alt="weekly"
                                    />
                                    {
                                        (weekly_deals_data[0].product_mrp - weekly_deals_data[0].product_price)> 0 ? (
                                            <div className="overly_content">
                                                <span className="save_text">Save : £{weekly_deals_data[0].product_mrp - weekly_deals_data[0].product_price}</span>
                                            </div>
                                        ) : null
                                    }
                                    
                                </div>

                                <div className="phone_details_section">
                                    <h4>{weekly_deals_data[0].product_name}</h4>

                                    <ul>
                                        {
                                            weekly_deals_data[0].storage_capacity ? (
                                                <li>
                                                    <span className="label">Storage</span>: {weekly_deals_data[0].storage_capacity} GB
                                                </li>
                                            ) : null
                                        }
                                        {
                                            weekly_deals_data[0].product_condition ? (
                                                <li>
                                                    <span className="label">Used</span>: ${weekly_deals_data[0].product_condition}
                                                </li>
                                            ) : null
                                        }
                                        {
                                            weekly_deals_data[0].Product_color ? (
                                                <li>
                                                    <span className="label">Color</span>: {weekly_deals_data[0].Product_color}
                                                </li>
                                            ): null
                                        }
                                        {
                                            weekly_deals_data[0].product_network ? (
                                                <li>
                                                    <span className="label">Network</span>: {weekly_deals_data[0].product_network}
                                                </li>

                                            ):null
                                        }
                                        {
                                            weekly_deals_data[0].product_price ? (
                                                <li>
                                                    <span className="label">Price</span>:
                                                    <span style={{ fontWeight: "bold", color: "var(--color-text)" }}>
                                                        £ {weekly_deals_data[0].product_price}
                                                    </span>
                                                    {
                                                        (weekly_deals_data[0].product_mrp - weekly_deals_data[0].product_price) > 0 ? (
                                                            <>
                                                                <span style={{ color: "grey" }}>
                                                                    <em> was </em>
                                                                </span>
                                                                <span style={{ textDecoration: "line-through", color: "red" }}>
                                                                    £{weekly_deals_data[0].product_mrp}
                                                                </span>
                                                            </>
                                                        ) : null
                                                    }
                                                    
                                                </li>

                                            ) : null
                                        }
                                        
                                    </ul>
                                    <ul>
                                        <li>
                                            <span className="label">Tel</span>:
                                            <a href="tel:+4407936819353">+44 (0) 7936819353</a>
                                        </li>
                                        <li>
                                            <span className="label">Whatsapp</span>:
                                            <a href="whatsapp://send?abid=+4407936819353&text=Hello%2C%20World!">
                                                                            (+44) (0) 7466456940
                                            </a>
                                        </li>
                                    </ul>
                                    <a href="#" className="btn_link">
                                        Click and Collect
                                    </a>
                                </div>
                            </div>

                        ) : null
                    }

                </section>

                <div className="popular_repair">

                    <div className="repair_service_cards">
                        {/* <Container> */}
                        {/* <h1></h1> */}
                        <div className="text-center">
                            <SectionHeading text="Most popular repairs"></SectionHeading>
                            {/* <h2>Choose brand, model and check our offer.</h2> */}
                        </div>
                        <ServicesCardSection></ServicesCardSection>
                        <div style={{ textAlign: "center", marginTop: "20px" }} className="ml-auto mr-auto">
                            <div className="view_more_btn sm-visble">
                                <a href="/repairing" className="">More models</a>
                            </div>
                        </div>
                        {/* </Container> */}
                    </div>

                </div>
                <RepairingProcess></RepairingProcess>

                <RepairItemsSection></RepairItemsSection>

                <QuickMapViewSection></QuickMapViewSection>
                <Footer></Footer>
            </div>
        );
    }



};
export default Home;
