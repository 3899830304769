import axios from "axios";

const CartSubmit = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_URL}/submitcart`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default CartSubmit;
