import React from "react";
import { Outlet } from "react-router-dom";
// ** React Imports
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// ** Styles
import "./VritacalMenu.css";
import "./VerticalOverlayMenu.css";
import "./style.css";
import { Helmet } from "react-helmet";
import Sidebar from "./Sidebar";
import { Button } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { IconSquarePlus, IconUserPlus, IconFilePlus, IconPencilPlus, IconDevicesPlus, IconHomePlus, IconPower, IconGridDots,IconAddressBook } from '@tabler/icons-react';
import { useUser } from '../../Context/AdminContext';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
const InnerContent = () => {


    // ** States
    const [menuVisibility, setMenuVisibility] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);



    // ** Update Window Width
    const handleWindowWidth = () => {
        setWindowWidth(window.innerWidth);
    };

    // ** Vars
    const location = useLocation();
    const navigate = useNavigate()
    //** This function will detect the Route Change and will hide the menu on menu item click
    useEffect(() => {
        if (menuVisibility && windowWidth < 800) {
            setMenuVisibility(false);
        }
    }, [location]);

    //** Sets Window Size & Layout Props
    useEffect(() => {
        if (window !== undefined) {
            window.addEventListener("resize", handleWindowWidth);
        }
    }, [windowWidth]);

    const userDataString = localStorage.getItem('user');
    const userData = JSON.parse(userDataString);
    const { logout: logoutContext } = useUser();

    
    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="/Core.css" />
            </Helmet>
            <div className="wrapper vertical-layout navbar-floating footer-static vertical-menu-modern menu-expanded">
                {menuVisibility ? <Sidebar></Sidebar> : null }
                
                <nav className="header-navbar navbar align-items-center floating-nav container-xxl navbar-shadow navbar navbar-expand-lg navbar-light">
                    <div className="navbar-container d-flex content">
                        <div className="bookmark-wrapper d-flex align-items-center">
                            <ul className="navbar-nav d-xl-none">
                                <li className="mobile-menu me-auto nav-item">
                                    {/* <a className="nav-menu-main menu-toggle hidden-xs is-active nav-link">
                                        
                                    </a> */}
                                    {/* <Button href="/admin/post">
                                        <IconSquarePlus/>
                                    </Button> */}
                                </li>
                            </ul>
                            {
                                userData.login_as === "Admin" ? (
                                    <>
                                        <li className="d-none d-lg-block nav-item">
                                            <Button variant="link" title="Post" href="/admin/product/post">
                                                <IconSquarePlus />
                                            </Button>
                                        </li>
                                        <li className="d-none d-lg-block nav-item">
                                            <Button variant="link" title="Staff" href="/admin/add_staff">
                                                <IconUserPlus />
                                            </Button>
                                        </li>
                                        <li className="d-none d-lg-block nav-item">
                                            <Button variant="link" title="New Deals" href="/admin/deals/post">
                                                <IconFilePlus />
                                            </Button>
                                        </li>
                                        <li className="d-none d-lg-block nav-item">
                                            <Button variant="link" title="Add Price List" href="/admin/add_a_price_list">
                                                <IconPencilPlus />
                                            </Button>
                                        </li>
                                        <li className="d-none d-lg-block nav-item">
                                            <Button variant="link" title="Add Phone" href="/admin/add_phone">
                                                <IconDevicesPlus />
                                            </Button>
                                        </li>
                                        <li className="d-none d-lg-block nav-item">
                                            <Button variant="link" title="Add Store Location" href="/admin/add_store_location">
                                                <IconHomePlus />
                                            </Button>
                                        </li>
                                        <li className="d-none d-lg-block nav-item">
                                            <Button variant="link" title="Add New slider" href="/admin/add_slider">
                                                <IconAddressBook />
                                            </Button>
                                        </li>
                                    </>
                                ): null
                            }
                            
                            
                        </div>
                        {/* <NavDropdown
                            id="nav-dropdown"
                            title={
                                <div>
                                  <div className="user-nav d-sm-flex d-none">
                                    <span className="user-name fw-bold">John Doe</span>
                                    <span className="user-status">Admin</span>
                                  </div>
                                  <div className="avatar">
                                    <img
                                      className=""
                                      src="/src/assets/images/portrait/small/avatar-s-11.jpg"
                                      alt="avatarImg"
                                      height="40"
                                      width="40"
                                    />
                                    <span className="avatar-status-online"></span>
                                  </div>
                                </div>
                            }
                            menuVariant="dark"
                            className="dropdown-user"
                            >
                            <NavDropdown.Item onClick={()=>{localStorage.clear();logoutContext();navigate('/admin/login')}}>Log Out</NavDropdown.Item>
                        </NavDropdown> */}
                        <UncontrolledDropdown tag='li' className='dropdown-user nav-item ms-auto' style={{listStyleType: "none", display: "flex", alignItems: "center"}}>
                            <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
                                <IconGridDots />
                                {/* <div className='user-nav d-sm-flex d-none'>
                                    <span className='user-name fw-bold'>{(userData && userData['user_address']) || 'John Doe'}</span>
                                    <span className='user-status'>{(userData && userData.login_as) || 'Admin'}</span>
                                </div>
                                <img className="" src="/src/assets/images/portrait/small/avatar-s-11.jpg" alt="avatarImg" height="40" width="40"/> */}
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem tag={Link} to='/admin/login' onClick={()=>{localStorage.clear();logoutContext();navigate('/admin/login')}}>
                                    <IconPower />
                                    <span className='align-middle'>Logout</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </nav>
                <div className="app-content content overflow-hidden">
                    <Outlet />
                </div>
                <div className="sidenav-overlay"></div>
                <footer className="footer footer-light footer-static">
                    <p className="clearfix mb-0">
                        <span className="float-md-start d-block d-md-inline-block mt-25">COPYRIGHT © 2023 <a href="https://www.facebook.com/virtualgarage.inc" target="_blank" rel="noopener noreferrer">Virtual Garage</a><span className="d-none d-sm-inline-block">, All rights Reserved</span></span>
                        <span className="float-md-end d-none d-md-block">Hand-crafted & Made with<i data-feather="heart"></i></span>
                        <button
                            className="btn btn-primary btn-icon scroll-top d-none d-sm-block"
                            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                        >
                            <i data-feather="arrow-up"></i>
                        </button>
                    </p>
                </footer>
            </div>
        </>

    )
}
export default InnerContent;