import React from "react";
import { useParams } from "react-router-dom";
import { SingleStoresData, UpdateStoresData } from './AdminApis/DataApi';
import AddProductForm from "./AdminComponent/AddProductForm";
import { useQuery } from "react-query";
const StoresDataEdit = ()=>{
    const { id } = useParams();
    const {
        data,
        isLoading,
        isError,
    } = useQuery("singleStoresData", () => SingleStoresData(id));
    if (isLoading) {
        return <h1>Loding..</h1>
    }
    if (isError) {
        return <h1>Error..</h1>
    }
    if (data) {
        const defultData = data.data[0]
        return (
            <div className="App">
                <AddProductForm actionFunction={UpdateStoresData} initialFormData={defultData} onSuccessPagePath="/admin/stores" />
            </div>
        )
    }
}
export default StoresDataEdit


