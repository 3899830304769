import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { SingleProductList } from "./Apis/SingleProduct";
import ThreeDParticleScene from "./component/ThreeDParticleScene";
import Header from "./component/Header/header";
import SectionHeading from "./component/sectionHeading";
import RelatedProduct from "./component/RelatedProduct";
import "./CategorySection.css";
import QuickMapViewSection from "./component/MapCard/QuickMapViewSection";
import Footer from "./component/footer";
const CategorySection = ({ dataArr }) => {
  const { category } = useParams();
  const { data, isLoading, isError } = useQuery("categoris", () =>
    SingleProductList(category, -1)
  );

  if (isLoading) {
    return <div>Loading</div>;
  }
  if (isError) {
    return <div>Error</div>;
  }

  if (data) {
    return (
      <div className="App">
        <ThreeDParticleScene/>
        <Header></Header>
          <section className="main_content_section product_list_section" key={category}>
            <div className="text-center" style={{paddingBottom: "50px"}}>
              <SectionHeading text={`Buy All ${category} in your Budget`}></SectionHeading>
            </div>
            <RelatedProduct productList={data}/>
          </section>

          <QuickMapViewSection/>
          <Footer/>

      </div>
    );
  }
};

export default CategorySection;
