import React from "react";
import ThreeDParticleScene from "./component/ThreeDParticleScene";
import Header from "./component/Header/header";
import SectionHeading from "./component/sectionHeading";
import { useQuery } from "react-query";
import Stores from "./Apis/stores";
import ContactUsMapCard from "./component/MapCard/ContactUsMapCard";


const ContactUS = ()=>{
  const { data, isSuccess,isLoading,isError } = useQuery("stores", () => Stores());
  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error fetching data</p>;
  }
   return(
    <div className="App">
        <ThreeDParticleScene/>
        <Header></Header>
        <section className="main_section">
            <div className="text-center">
                <SectionHeading text="Contact Us"/>
            </div>
        </section>

        {isSuccess && data.data.map((item) => (
                (item.map_status) ? (
                <ContactUsMapCard
                mapUrl={item.map_link}
                locationName={item.store_name}
                address={item.store_address}
                phone={item.store_phone}
                mobile={item.store_mobile}
                email={item.store_email}
                whatsapp={item.whatsapp_number}
              />
                ): null
              
            ))}
        
    </div>
   );
}
export default ContactUS;