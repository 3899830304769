import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import ProductCategory from '../../Data/ProductCategory'
import ProductCondition from '../../Data/ProductCondition'
import ProductStatus from '../../Data/ProductStatus'
import PhoneAvailabilityStatus from '../../Data/PhoneAvailabilityStatus'
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import LocationDropDown from "./LocationDropDown";
import StaffDropDownList from "./StaffDropDownList";

const AddProductForm = ({ initialFormData, actionFunction, onSuccessPagePath }) => {
    const [formData, setFormData] = useState(initialFormData);
    const [selectBoxData] = useState({ p_condition: ProductCondition, product_condition: ProductCondition, phone_condition: ProductCondition,  p_category: ProductCategory, p_status: ProductStatus, product_status: ProductStatus, weekly_deal: ProductStatus, phone_availability: PhoneAvailabilityStatus })
    const navigate = useNavigate()
    const { mutate } = useMutation(actionFunction, {
        onSuccess: (response) => {
            if (onSuccessPagePath) {
                navigate(onSuccessPagePath)
            }

        },
        onError: (err) => {
            alert(err)
            console.log(err);
        }
    });
    // console.log(selectBoxData);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const { name } = e.target
        setFormData({
            ...formData,
            [name]: file,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        mutate(formData)

    };

    return (
        <Container>
            <Form onSubmit={handleSubmit}>

                {Object.keys(initialFormData).map((key) => (
                    key !== 'id' ? (
                        <Form.Group
                            key={key}
                            as={Row}
                            className="mb-3"
                            controlId="formPlaintext"
                        >
                            <Form.Label column sm="2">
                                {key}
                            </Form.Label>
                            <Col sm="10">
                                {key === "p_image" ||
                                    key === "product_image" ? (
                                        <Form.Control
                                            type="file"
                                            size="md"
                                            accept="image/*"
                                            label="Choose Image"
                                            name={key}
                                            onChange={handleFileChange}
                                        />
                                    ) : key === "p_category" ||
                                        key === "p_condition" ||
                                        key === "p_status" ||
                                        key === 'product_status' ||
                                        key === 'weekly_deal' ||
                                        key === 'product_condition' || 
                                        key === 'phone_condition' ||
                                        key === 'phone_availability' ? (
                                            <Form.Select name={key} value={formData[key]} onChange={handleChange}>
                                                {
                                                    selectBoxData[key].map((item) => (<option key={item.value} value={item.value}>{item.text}</option>))
                                                }
                                            </Form.Select>
                                        ) : key === 'phone_location' ||
                                            key === 'sold_from' ?(
                                            <LocationDropDown currentValue={formData[key]} name={key} handleChange={handleChange}/>
                                        ) : key === 'sold_by' ?(
                                            <StaffDropDownList currentValue={formData[key]} name={key} handleChange={handleChange}/>
                                        ) : key === 'sales_date'? (
                                            <Form.Control
                                                type="date"
                                                name={key}
                                                value={formData[key]}
                                                onChange={handleChange}
                                            />
                                        ) : (
                                            <Form.Control
                                                type="text"
                                                name={key}
                                                value={formData[key]}
                                                onChange={handleChange}
                                            />
                                        )}
                            </Col>
                        </Form.Group>
                    ) : null
                ))}
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </Container>
    );
};

export default AddProductForm;
