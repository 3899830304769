import 'bootstrap/dist/css/bootstrap.css';
import "./App.css";
import Home from "./Home";
import Products from "./Products";
import ProductDetails from "./ProductDetails";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Suspense, useEffect } from "react";
import Repairing from "./Repairing";
import ContactUS from "./ContactUS";
import CategorySection from "./CategorySection";
import Cart from "./Cart";
import CheckOut from "./CheckOut";
import { CheckoutProvider } from './Context/CheckoutContext';
import { CartProvider } from './Context/CartContext';
import RepairingBlogContent from './RepairingBlogContent';
import "./Responsive.css";
import Dashboard from './Admin/Dashboard';
import ProtectedRoutes from './Admin/AdminComponent/ProtectedRoutes';
import InnerContent from './Admin/AdminComponent/InnerContent';
import PublicRoutes from './Admin/AdminComponent/PublicRoutes';
import Login from './Admin/Login';
import Deals from './Admin/Deals';
import NewArrivals from './Admin/NewArrivals';
import MainProductEdit from './Admin/MainProductEdit';
import AddProduct from './Admin/AddProduct';
import AddDeals from './Admin/AddDeals';
import DealsEdit from './Admin/DealsEdit';
import Staff from './Admin/Staff';
import StaffEdit from './Admin/StaffEdit';
import AddStaff from './Admin/AddStaff';
import PriceList from "./Admin/PriceList";
import PriceListEdit from './Admin/PriceListEdit';
import AddPriceList from './Admin/AddPriceList';
import PhoneRecords from './Admin/PhoneRecords';
import PhoneRecordsEdit from './Admin/PhoneRecordsEdit'
import AddPhoneRecords from './Admin/AddPhoneRecords';
import Stores from './Admin/Stores';
import AddStores from './Admin/AddStores';
import StoresDataEdit from './Admin/StoresDataEdit';
import { AdminProvider } from './Context/AdminContext';
import StaffDashboard from './Admin/StaffDashboard';
import StaffPhoneRecordsPage from './Admin/StaffPhoneRecordsPage';
import StripeContainer from './PaymentComponent/StripeContainer';
import ThankYou from './component/ThankYou';
import SliderController from './Admin/SliderController';
import SliderDetailsEdit from './Admin/SliderDetailsEdit';
import AddSlider from './Admin/AddSlider';
import ProductCategoryData from './Admin/AdminComponent/ProductCategoryData';
function App() {
    const client = new QueryClient({
        defaultOptions: {
            queries: {
                suspense: true
            }
        }
    })
    // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

    // const options = {
    //     mode: 'payment',
    //     amount: 1099,
    //     currency: 'usd',
    // };
    // console.log(process.env.REACT_APP_SERVER_URL);
    return (
        <CartProvider>
            <CheckoutProvider>
            <QueryClientProvider client={client}>
                <AdminProvider>
                <Suspense>
                    <BrowserRouter>
                        <Routes>
                            <Route>
                                <Route index element={<Home />}></Route>
                                <Route path='/products/' element={<Products />} ></Route>
                                <Route path='/repairing/'>
                                    <Route index element={<Repairing />} />
                                    <Route path=':repairingHeading' element={<RepairingBlogContent />} />
                                </Route>
                                <Route path='/products/:category'>
                                    <Route index element={<CategorySection />} />
                                    <Route path=':productid' element={<ProductDetails />} />
                                </Route>
                                <Route path='/contactus/' element={<ContactUS />} />
                                <Route path='/cart/' element={<Cart />} />
                                <Route path='/checkout/' >
                                    <Route index element={<CheckOut />} />
                                    <Route path=':uid' element={<StripeContainer/>} />
                                </Route>
                                <Route path='/success/:id' element={<ThankYou/>} />
                            </Route>

                            {/* =============== ALL PRIVATE ROUTES ==================== */}
                            {/* protected routs checking if user already exist then it will render it's child elements */}
                            <Route path="/" element={<ProtectedRoutes />}>
                                <Route path="/admin" element={<InnerContent />}>
                                    {/* <Route path="/admin" element={<Navigate replace to="/admin/dashboard" />} /> */}
                                    {/* <Route path="/admin/dashboard" element={<ProtectedRoute role="Admin"><Dashboard /></ProtectedRoute>} /> */}
                                    <Route path="/admin/dashboard" element={<Dashboard />} />
                                    <Route path="/admin/deals" element={<Deals />} />
                                    <Route path="/admin/new_arrivals" element={<NewArrivals/>} />
                                    <Route path="/admin/iphones" element={<ProductCategoryData category="Iphones"/>} />
                                    <Route path="/admin/ipads" element={<ProductCategoryData category="Ipads"/>} />
                                    <Route path="/admin/employee_details" element={<Staff/>} />
                                    <Route path="/admin/a_pricelist" element={<PriceList/>} />
                                    <Route path="/admin/phones_record" element={<PhoneRecords/>} />
                                    <Route path="/admin/stores" element={<Stores/>} />
                                    <Route path="/admin/staff_entrypoint" element={<StaffDashboard/>} />
                                    <Route path="/admin/s_phone_records" element={<StaffPhoneRecordsPage/>} />
                                    <Route path="/admin/androidphones" element={<ProductCategoryData category="Androids"/>} />
                                    <Route path="/admin/computers" element={<ProductCategoryData category="Computers"/>} />
                                    <Route path="/admin/tablets" element={<ProductCategoryData category="Tablets"/>} />
                                    <Route path="/admin/accessories" element={<ProductCategoryData category="Accessories"/>} />
                                    <Route path="/admin/gadgets" element={<ProductCategoryData category="Gadgets"/>} />
                                    <Route path="/admin/slider_controller" element={<SliderController/>} />
                                    <Route path="*" element={<h1>No data found</h1>} />
                                    {/* ====== New Upload ======== */}
                                    <Route path="/admin/product/post" element={<AddProduct/>} />
                                    <Route path="/admin/deals/post" element={<AddDeals />} />
                                    <Route path="/admin/add_staff" element={<AddStaff/>} />
                                    <Route path="/admin/add_a_price_list" element={<AddPriceList/>} />
                                    <Route path="/admin/add_phone" element={<AddPhoneRecords/>} />
                                    <Route path="/admin/add_store_location" element={<AddStores/>} />
                                    <Route path="/admin/add_slider" element={<AddSlider/>} />


                                    {/* ======= Edit routs ========== */}
                                    <Route path="/admin/main_product_edit/:id" element={<MainProductEdit />} />
                                    <Route path="/admin/deals_edit/:id" element={<DealsEdit />} />
                                    <Route path="/admin/employee_details_edit/:id" element={<StaffEdit />} />
                                    <Route path="/admin/a_pricelist_edit/:id" element={<PriceListEdit />} />
                                    <Route path="/admin/phone_records_edit/:id" element={<PhoneRecordsEdit />} />
                                    <Route path="/admin/stores_details_edit/:id" element={<StoresDataEdit />} />
                                    <Route path="/admin/slider_details_edit/:id" element={<SliderDetailsEdit />} />
                                    
                                    

                                    
                                </Route>
                            </Route>
                            {/* public routs checking if user already exist then it will not render it's child elements */}
                            <Route path="/admin" element={<PublicRoutes/>}>
                                <Route path="login" element={<Login/>}/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </Suspense>
                </AdminProvider>
            </QueryClientProvider>
            </CheckoutProvider>
        </CartProvider>
        


    );

}

export default App;
