import React, { useState } from 'react';
import './offcanvas.css';
import HeaderMenu from './headerMenu';
import { MenuItem } from './headerMenu';
import { IconArrowBack, IconMenu2,IconMenuDeep } from '@tabler/icons-react';

const OffCanvasMenu = ({ menuActive, onClick, headerMenu }) => {

  return (
    <div className={`off-canvas-menu ${menuActive ? 'active' : ''}`}>
      <div className="close-menu">
        <a className="close_nav" href='javascript:' onClick={onClick}><IconArrowBack/></a>
      </div>

      <ul className='flex mobile_menu'>
          {headerMenu.map((item) => (
              <MenuItem key={item.key} item={item} />
          ))}
      </ul>

    </div>
  );
};

const OffCanvasOverlay = ({ menuActive, onClick }) => {
  return (
    <div
      className={`off-canvas-overlay ${menuActive ? 'active' : ''}`}
      onClick={onClick}
    ></div>
  );
};

const OffCanvasMain = ({headerMenu}) => {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <div className='mobile_header'>
      <a href='javascript:' className={`menu-open ${menuActive ? 'toggle' : ''}`} onClick={toggleMenu}>
        <span className='bar_span'></span>
      </a>
      <OffCanvasMenu menuActive={menuActive} onClick={toggleMenu} headerMenu={headerMenu} />
      <OffCanvasOverlay menuActive={menuActive} onClick={toggleMenu} />
    </div>
  );
};

export default OffCanvasMain;

