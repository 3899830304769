import React from 'react';
import { IconStarFilled } from '@tabler/icons-react';
import "./Review.css";

const Review = () => {
  return (
    <div className="content_comment_container">
      <div className="comment_container flex">
        <img
          alt=""
          src="https://secure.gravatar.com/avatar/53444f91e698c0c7caa2dbc3bdbf93fc?s=60&amp;d=mm&amp;r=g"
          srcSet="https://secure.gravatar.com/avatar/53444f91e698c0c7caa2dbc3bdbf93fc?s=120&amp;d=mm&amp;r=g 2x"
          className="avatar avatar-60 photo"
        />
        <div className="comment-text">
          <div className="star-rating" role="img" aria-label="Rated 5 out of 5">
            <IconStarFilled/>
            <IconStarFilled/>
            <IconStarFilled/>
            <IconStarFilled/>
            <IconStarFilled/>
          </div>
          <p className="meta">
            <strong className="woocommerce-review__author">Wpbingo </strong>
            <span className="woocommerce-review__dash">–</span>
            <time className="woocommerce-review__published-date" dateTime="2018-10-04T03:17:11+00:00">October 4, 2018</time>
          </p>
        </div>
      </div>
      <div className="description">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fringilla augue nec est tristique auctor. Donec non
          est at libero vulputate rutrum. Morbi ornare lectus quis justo gravida semper. Nulla tellus mi, vulputate adipiscing
          cursus eu, suscipit id nulla.
        </p>
      </div>
    </div>
  );
};

export default Review;
