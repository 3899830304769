const navLinksArray = [
    {
        text: "Staff Area",
        url: "/admin/staff_entrypoint",
        backgroundColor: "bg-info",
        access: "Staff"
    },
    {
        text: "Home",
        url: "/admin/dashboard",
        access: "Admin"
    },
    {
        text: "Deal of the Week",
        url: "/admin/deals",
        access: "Admin"
    },
    {
        text: "Slider Controller",
        url: "/admin/slider_controller",
        access: false
    },
    {
        text: "New Arrivals",
        url: "/admin/new_arrivals",
        access: "Admin"
    },
    {
        text: "Iphones",
        url: "/admin/iphones",
        access: "Admin"
    },
    {
        text: "Ipads",
        url: "/admin/ipads",
        access: "Admin"
    },
    {
        text: "Android Phones",
        url: "/admin/androidphones",
        access: "Admin"
    },
    {
        text: "Tablets",
        url: "/admin/tablets",
        access: "Admin"
    },
    {
        text: "Computers",
        url: "/admin/computers",
        access: "Admin"
    },
    {
        text: "Accessories",
        url: "/admin/accessories",
        access: "Admin"
    },
    {
        text: "Gadgets",
        url: "/admin/gadgets",
        access: "Admin"
    },
    {
        text: "Staffs",
        url: "/admin/employee_details",
        backgroundColor: "bg-danger",
        access: "Admin"
    },
    {
        text: "PriceList",
        url: "/admin/a_pricelist",
        backgroundColor: "bg-danger",
        access: "Admin"
    },
    {
        text: "Sales Record",
        url: "/admin/sales_record",
        backgroundColor: "bg-primary",
        access: "Admin"
    },
    {
        text: "Phones Record",
        url: "/admin/s_phone_records",
        backgroundColor: "bg-info",
        access: false
    },
    {
        text: "Our Stores",
        url: "/admin/stores",
        backgroundColor: "bg-info",
        access: "Admin"
    }
];
export default navLinksArray