import React, { useState } from 'react';
import {IconChevronDown} from "@tabler/icons-react";
import OffCanvasMain from './offCanvasMenu';

const MenuItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const renderItem = () => {
    if (!item.submenu) {
      return <a href={`/${item?.slug}`}>{item.key}</a>;
    } else {
      return (
        <button
          className={`dropdown_btn ${isOpen ? 'active' : ''}`}
          onClick={toggleDropdown}
        >
          {item.key} <IconChevronDown/>
        </button>
      );
    }
  };

  return (
    <li className={isOpen ? 'active' : ''} key={item.key}>
      {renderItem()}
      {item.submenu && (
        <ul className={isOpen ? 'submenu show' : 'submenu'}>
          {Array.isArray(item.submenu)
            ? item.submenu.map((subItem) => (
                <MenuItem key={subItem.key} item={subItem} />
              ))
            : <MenuItem key={item.submenu.key} item={item.submenu} />}
        </ul>
      )}
    </li>
  );
};

const HeaderMenu = ({ headerMenu }) => {
  return (

    <ul className='flex desktop_dropdown'>
        {headerMenu.map((item) => (
            <MenuItem key={item.key} item={item} />
        ))}
    </ul>
    
  );
};

export {HeaderMenu, MenuItem};
