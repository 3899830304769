import React, { createContext, useContext, useState } from "react";

const CartContext = createContext();
const useCartContext = ()=>{
    return useContext(CartContext)
}
const CartProvider = ({children})=>{
    const [productID, setProductID] = useState(null);
    return(
        <CartContext.Provider value={{productID, setProductID}}>
            {children}
        </CartContext.Provider>
    )
}
export {useCartContext, CartProvider}




