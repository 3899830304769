import Header from "./component/Header/header";
import { useQuery } from "react-query";
import SectionHeading from "./component/sectionHeading";
import ServiceData from "./Apis/ServiceData";
import ServicesCard from "./component/ServicesCard";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import QuickMapViewSection from "./component/MapCard/QuickMapViewSection";
import Footer from "./component/footer";
import ThreeDParticleScene from "./component/ThreeDParticleScene";

const Repairing = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { data, isSuccess, isLoading, isError } = useQuery("serviceCard", () => ServiceData());
  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error fetching data</p>;
  }
  return (
    <div className="App">
      <ThreeDParticleScene/>
      <Header />
      <section className="main_content" style={{paddingTop: "100px",paddingBottom: "100px"}}>
        <div className="text-center">
          <SectionHeading text="Our Repairing" />
        </div>
          <div className="repair_service_card_group flex justify-center">
            {isSuccess &&
              data.data.map((item) =>
                item.is_repairing === 1 ? (
                  <ServicesCard
                    key={item.s_title}
                    title={item.s_title}
                    shortDescription={item.s_short_description}
                    image={item.s_image}
                    classname="sm-visible"
                  />
                ) : null
              )}
            </div>
      </section>
      <QuickMapViewSection></QuickMapViewSection>
      <Footer></Footer>
    </div>
  );
};
export default Repairing;
