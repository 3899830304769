import React from 'react';
import AddProductForm from './AdminComponent/AddProductForm';
import { AddPriceListData } from './AdminApis/DataApi';
const AddPriceList = ()=>{
    const formFields = {
        p_name: "",
        p_model: "",
        p_category: "",
        price: 0,
        min_price: 0,
        product_desc: " ",
        p_status: 1
    };
    
    
    return(
        <div className="App">
            <AddProductForm initialFormData={formFields} actionFunction={AddPriceListData} onSuccessPagePath="/admin/a_pricelist" />
        </div>
    )
}
export default AddPriceList



