import React from "react";
import './headerList.css';
import { useQuery } from "react-query";
import ProductCategoris from "../../Apis/ProductCategoris";
const HeaderList = ()=>{
    const { data, isFetched, isSuccess} = useQuery("categoris", () => ProductCategoris());
    if (isSuccess && isFetched){
    return(
        <div className="full_container">
            <ul className="product_category_list flex justify-center">
            {data.data.map((item)=>{
                return(
                    <li key={item.p_category}>
                <a href={`products/${item.p_category}`}>{item.p_category}</a>
                </li>
                )
            })}
                {/* <li>
                    <a href="/product-details/123">iPhone 12 Pro</a>
                    <a href="/product-details/123">iPhone 12</a>
                    <a href="/product-details/123">iPhone X</a>
                </li> */}
            </ul>
        </div>
    );
}
}
export default HeaderList