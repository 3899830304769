import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { SingleDealsData } from '../Apis/DealsData';
import AddProductForm from './AdminComponent/AddProductForm';
import { UpdateDealsData } from './AdminApis/DataApi';
const DealsEdit = () => {
    const { id } = useParams();
    const {
        data,
        isLoading,
        isError,
    } = useQuery("singleDealsData", () => SingleDealsData(id));
    if (isLoading) {
        return <h1>Loading..</h1>
    }
    if (isError) {
        <h1>Error</h1>
    }
    if (data) {
        const defultData = data.data[0]
        
        return (
            <div className="App">
                <AddProductForm actionFunction={UpdateDealsData} initialFormData={defultData} onSuccessPagePath="/admin/deals" />
            </div>
        )
    }
}
export default DealsEdit
