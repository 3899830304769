const PhoneAvailabilityStatus = [
    {
        value: "Available",
        text: "Available"
    },
    {
        value: "Sold",
        text: "Sold"
    },
];

export default PhoneAvailabilityStatus
