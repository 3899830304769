import react, { useEffect, useState } from 'react';
import RepairingData from './Data/RepairingData';
import { useParams, useNavigate } from "react-router-dom";
import ThreeDParticleScene from './component/ThreeDParticleScene';
import Header from './component/Header/header';
import SectionHeading from "./component/sectionHeading";
import { Container, Row, Col } from 'react-bootstrap';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import './RepairingBlogContent.css'
const RepairingBlogContent = () => {

    const { repairingHeading } = useParams();
    const [repairingdata, setRepairingdata] = useState()
    useEffect(() => {
        const data = RepairingData();
        const foundData = data.find(item => item.path === `/${repairingHeading}`);
        setRepairingdata(foundData);
    }, [repairingHeading]);

    return (
        <div className="App">
            <ThreeDParticleScene />
            <Header />
            <section className="blog_section">
                <div className="text-center">
                    <SectionHeading text="COMMON PROBLEMS"></SectionHeading>
                </div>
                {
                    (repairingdata?.c_problems.length) ?(
                        <Container>
                            <Row>
                                <div className="header_card_section">
                                    <OwlCarousel className="owl-theme" loop margin={10} nav items={6} stagePadding={40}>
                                        {repairingdata?.c_problems.map((item, index) => (
                                            <div className="item card flex heading_card" key={index}>
                                                <div className="image_section">
                                                    <img src={`${process.env.REACT_APP_FRONTEND_DATA_URL}/${item.imageSrc}`} alt={item.altText}/>
                                                </div>
                                                <div className="text_section">
                                                    <h3>{item.name}</h3>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>

                            </Row>
                        </Container>
                    ) : null
                }
                
            </section>
            {repairingdata && (
                <div>
                    {/* Use repairingdata to display the content */}
                    <h1>{repairingdata?.c_problems[0]?.name}</h1>
                    {/* Render other content based on repairingdata */}
                </div>
            )}
        </div>
    )
}
export default RepairingBlogContent