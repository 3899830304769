import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useUser } from '../../Context/AdminContext';
const useAuth = () => {
    const user = localStorage.getItem('user')
    const {user: contextUser} = useUser()
    console.log(contextUser);
    
    if (user || contextUser) {
        console.log(user);
        return true
    } else {
        return false
    }
}

const ProtectedRoutes = (props) => {


    const auth = useAuth()

    // console.log(auth);
    // console.log("ProtectedRoutes");
    
    
    return auth ? <Outlet /> : <Navigate to="/admin/login" />
}


export default ProtectedRoutes;