import React, {useState} from 'react';
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { deleteSlider, updateSliderStatus } from './AdminApis/DataApi';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import Slider from '../Apis/Slider';
const SliderController = () => {
    
   const {
        data,
        isLoading,
        isError,
    } = useQuery("sliderDetails",Slider);
    const queryClient = useQueryClient();

    const [deleteID, setDeleteID] = useState(null)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true); }
    const { mutate: removeSlider } = useMutation(deleteSlider, {
        onSuccess: (response) => {
          handleClose();
          queryClient.invalidateQueries("sliderDetails");
        },
        onError: (err) => {
          console.log(err);
        }
    });
    const { mutate: updateSliderData } = useMutation(updateSliderStatus, {
        onSuccess: (response) => {
          queryClient.invalidateQueries("sliderDetails");
        },
        onError: (err) => {
          console.log(err);
        }
    });
    async function delete_data(id) {
        await removeSlider({ id: id })
    }
    if (isLoading) {
        return <h1>Loading...</h1>
    }
    if (isError) {
        return <h1>Error...</h1>
    }
    if (data) {
        const data_column = Object.keys(data.data[0]).map((key) => ({
            name: key,
            selector: key,
            sortable: true,
        }));
        console.log(data_column);
        
        const data_array = data.data;
        return (
            <div className="App">
                <div className="container-xxl p-0">
                    <div className="overflow-x-scroll">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    {data_column.map((item) => {
                                        return <th key={item.name}>{item.name}</th>;
                                    })}
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data_array.map((item, index) => {
                                    return (
                                        <tr key={item.id}>
                                            {data_column.map((key) => (
                                                <td key={key.name}>{item[key.name]}</td>
                                            ))}
                                            <td>
                                                <div className="d-flex" style={{ columnGap: "20px" }}>
                                                    <div className="actions">
                                                        <div className="d-flex" style={{ columnGap: "20px" }}>
                                                            <Button variant="success" href={`/admin/slider_details_edit/${item.id}`}>
                                                                Edit
                                                            </Button>
                                                            <Button variant="warning" onClick={() => {
                                                                updateSliderData({new_status: item.p_status ? 0 : 1, id: item.id})
                                                                }}>
                                                                {item.p_status ? "Hide" : "Show"}
                                                            </Button>
                                                            <Button variant="danger" onClick={() => {
                                                                handleShow();
                                                                setDeleteID(item.id)
                                                            }}>
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm message...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this item</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={() => { delete_data(deleteID) }}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

}
export default SliderController;


