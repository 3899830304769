import axios from "axios";
const PaymentIntent = async (data) => {
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/create-payment-intent`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
export default PaymentIntent;