const ProductCondition = [
    {
        value: "Used-Grade A",
        text: "Used-Grade A"
    },
    {
        value: "New",
        text: "New"
    },
    {
        value: "Used",
        text: "Used"
    },
    {
        value: "Refurbished",
        text: "Refurbished"
    },
    {
        value: "used-Like New",
        text: "used-Like New"
    },
    {
        value: "Used-Grade B",
        text: "Used-Grade B"
    },
    {
        value: "Used-Grade C",
        text: "Used-Grade C"
    },
    {
        value: "Refurbished-Grade A",
        text: "Refurbished-Grade A"
    },
    {
        value: "Refurbished-Grade B",
        text: "Refurbished-Grade B"
    },
    {
        value: "Refurbished-Grade C",
        text: "Refurbished-Grade C"
    }
];
export default ProductCondition