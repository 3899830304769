import React from 'react';
import Table from "react-bootstrap/Table";
import {StaffPriceListData } from './AdminApis/DataApi';
import { useQuery  } from 'react-query';
const StaffDashboard = ()=>{
    const {
        data,
        isLoading,
        isError,
    } = useQuery("staffPriceListRecords",StaffPriceListData);

    if (isLoading) {
        return <h1>Loading...</h1>
    }
    if (isError) {
        return <h1>Error...</h1>
    }
    if (data) {
        const data_column = Object.keys(data.data[0]).map((key) => ({
            name: key,
            selector: key,
            sortable: true,
        }));
        const data_array = data.data;
        return (
            <div className="App">
                <div className="container-xxl p-0">
                    <div className="overflow-x-scroll">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    {data_column.map((item) => {
                                        return <th key={item.name}>{item.name}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {data_array.map((item, index) => {
                                    return (
                                        <tr key={item.id}>
                                            {data_column.map((key) => (
                                                <td key={key.name}>{item[key.name]}</td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
               
            </div>
        )
    }
}
export default StaffDashboard




