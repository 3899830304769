import React from "react";
import { Form } from "react-bootstrap";
import { useCheckoutContext } from "../Context/CheckoutContext";

const PaymentMethodFormElement = () => {
  const {selectedPaymentMethod, setSelectedPaymentMethod} = useCheckoutContext();

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  return (
    <div className="input_elements">
      <Form.Check
        type="radio"
        name="payment_method"
        value="cod"
        id="cod-radio"
        label="Cash on Delivery"
        className="radio-inline"
        checked={selectedPaymentMethod === "cod"}
        onChange={handlePaymentMethodChange} // Add onChange handler
      />
      <Form.Check
        type="radio"
        name="payment_method"
        value="pod"
        className="radio-inline"
        id="pod-radio"
        label="POS on Delivery"
        checked={selectedPaymentMethod === "pod"}
        onChange={handlePaymentMethodChange} // Add onChange handler
      />
      <Form.Check
        type="radio"
        name="payment_method"
        value="online"
        className="radio-inline"
        id="online-radio"
        label="Online Payment"
        checked={selectedPaymentMethod === "online"}
        onChange={handlePaymentMethodChange} // Add onChange handler
      />
    </div>
  );
};

export default PaymentMethodFormElement;
