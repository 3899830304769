import React from "react";
import FullContentLink from "../fullContentLink";
const QuickViewCard = ({mapUrl, locationName, address, phone, mobile, whatsapp, email})=>{
    const phoneNumber = "(+44) (0) 7466456940";

    // Remove all non-numeric characters from the phone number
    let formattedPhoneNumber;
    if(whatsapp){
        formattedPhoneNumber = whatsapp.replace(/\D/g, '');
    }
   

    return(
        <div className="map-block col-lg-4 col-md-6 col-sm-12">
            <div className="inner-box">
              <div className="image-box">
                <iframe
                  className="responsive-iframe"
                  src={mapUrl}
                ></iframe>
              </div>
              <div className="lower-content">
                <h3>{locationName}</h3>
                <p className="text">{address}</p>
                <ul>
                    {
                        phone ? (
                            <li>Tel: <a href={`tel:${phone.replace(/\s/g, '')}`}>{phone}</a></li>
                        ) : null
                    }
                    {
                        mobile ? (
                            <li>Mobile: <a href={`tel:${mobile.replace(/\s/g, '')}`}>{mobile}</a></li>
                        ) : null
                    }
                    
                    {
                        formattedPhoneNumber ? (
                            <li>Whatsapp: <a href={`https://wa.me/${formattedPhoneNumber}`}>{whatsapp}</a></li>
                        ) : null
                    }
                    {formattedPhoneNumber ? (
                        <li>Email: <a href={`mailto:${email}`}>{email}</a></li>
                    ) : null}
                </ul>
                <a className="view-map" href="/contactus">
                  See On Map
                </a>
              </div>
            </div>
            
        </div>
    )
}
export default QuickViewCard