import React, { useContext, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import Header from "./component/Header/header";
import ThreeDParticleScene from "./component/ThreeDParticleScene";
import SectionHeading from "./component/sectionHeading";
import { Container, Row, Form, Col, Button, InputGroup } from "react-bootstrap";
import AddressForm from "./component/AddressForm";
import "./CheckOut.css";
import PaymentMethodFormElement from "./component/PaymentMethodFormElement";
import DeliveryMethodFormElement from "./component/DeliveryMethodFormElement";
import {useCheckoutContext } from "./Context/CheckoutContext";
import TermsAgreeFormElement from "./component/TermsAgreeFormElement";
import toast, { Toaster } from 'react-hot-toast';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import CartSubmit from "./Apis/CartSubmit";
import { useCartContext } from "./Context/CartContext";
import { SingleProduct } from "./Apis/SingleProduct";
import CheckoutInvoiceTable from "./component/CheckoutInvoiceTable";
function isUKPhoneNumber(phoneNumber) {
  // Regular expression for UK phone numbers
  const ukPhoneNumberPattern = /^(?:\+44|0)[1-9]\d{9}$/;

  // Remove spaces and hyphens from the input phone number
  const cleanedPhoneNumber = phoneNumber.replace(/[\s\-]/g, '');

  // Test if the cleaned phone number matches the UK format
  return ukPhoneNumberPattern.test(cleanedPhoneNumber);
}
function isValidEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
}
const CheckOut = () => {
  const navigate = useNavigate()
  const {productID} = useCartContext();
 
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError } = useMutation(CartSubmit,{
    onSuccess: (response)=>{
      if (response.added === true) {
        
        navigate('/checkout/123'); // Redirect to a success page
      } 
    }
  })
  const { mutate : singleDataMutate, isLoading : singleDataLoading, data: singleProductData } = useMutation(SingleProduct)
  const notify = () => {
    toast.error('Please agree to the terms and conditions', {
      duration: 2000, // Duration in milliseconds
    })
  };
  const {
    formData,
    setFormData,
    selectedPaymentMethod,
    selectedShippingMethod,
    agree,
  } = useCheckoutContext();

  const isProductIDValid = !!productID; 

//   const {
//     data: singleProductData,
//     isLoading: isSingleProductLoading,
//   } = useQuery("singleProductData", () => SingleProduct(productID.productid),{
//     enabled: isProductIDValid,
//   });


  useEffect(() => {
    if (!productID) {
        navigate('/products'); 
        // return null; 
    }
    else{
        singleDataMutate(productID.productid)

    }
  }, [isProductIDValid, navigate]);


  const onSubmit = async (event) => {
    event.preventDefault(); // Prevent default submission
    // check Form data 
    if(!formData.firstname){
      setFormData({
        ...formData,
        firstname: false
      })
      return;
    }else if(!formData.lastname){
      setFormData({
        ...formData,
        lastname: false
      })
      return;
      
    }else if(!formData.address_1){
      setFormData({
        ...formData,
        address_1: false
      })
      return;
    }else if(!formData.telephone){
      setFormData({
        ...formData,
        telephone: false
      })
      return;
    }else if(!isUKPhoneNumber(formData.telephone)){
      setFormData({
        ...formData,
        telephone: false
      })
      return;
    }else if(!isValidEmail(formData.email)){
      setFormData({
        ...formData,
        email: false
      })
    }else if(!formData.city || !formData.zone_id){
      setFormData({
        ...formData,
        city: false
      })
    }
    // check payment method
    if(!selectedPaymentMethod || !selectedShippingMethod){
      window.location.reload();
    }
    // check agree with terms or not
    if(!agree){
      notify();
      return;
    }
    
    try {
      // console.log(formData, selectedPaymentMethod, selectedShippingMethod, agree);

      await mutate({formData, selectedPaymentMethod, selectedShippingMethod, agree});

      
    } catch (e) {
      alert(`Registration failed! ${e.message}`);
    }
  }
  if(singleDataLoading){
    return(
      <h1>Loading</h1>
    )
  }

//   if(singleProductData && !singleProductData.data.length){
//     navigate('/products');
//     return null;
//   }
//   if(singleProductData){
//     console.log(singleProductData);
//   }
    if (singleProductData) {
    return (
        <div className="App">
          <ThreeDParticleScene />
          <Header />
          <section
            className="main_content_section"
            style={{ background: "var(--theme-dark)" }}
          >
            <div className="text-center">
              <SectionHeading text={`Checkout Product`}></SectionHeading>
            </div>
            <Form onSubmit={onSubmit}>
              <Container className="check_out_content">
                <Row>
                  <Col lg={4} md={4} sm={12}>
                    <div className="confirm_form_section">
                      <h2>
                        <span>1</span>Customer Information
                      </h2>
    
                      <AddressForm />
                    </div>
                  </Col>
    
                  <Col lg={8} md={8} sm={12}>
                    <Row className="row-payment-delivery-order">
                      <Col
                        md={6}
                        sm={12}
                        className="payment-methods confirm_form_section"
                      >
                        <div className="page-section ws-box">
                          <div className="section-head">
                            <h2>
                              <span>2</span> Payment Method
                            </h2>
                          </div>
                          <p>Select a payment method</p>
                          <PaymentMethodFormElement/>
                        </div>
                      </Col>
                      <Col
                        md={6}
                        sm={12}
                        className="delivery-methods confirm_form_section"
                      >
                        <div className="page-section ws-box">
                          <div className="section-head">
                            <h2>
                              <span>3</span> Delivery Method
                            </h2>
                          </div>
                          <p>Select a delivery method</p>
                          <DeliveryMethodFormElement/>
                        </div>
                      </Col>
                      <Col
                        md={12}
                        sm={12}
                        className="details-section-wrap confirm_form_section"
                      >
                        <div className="page-section ws-box">
                          <div className="section-head">
                            <h2>
                              <span>4</span> Order Overview
                            </h2>
                          </div>
                          <CheckoutInvoiceTable singleProductData={singleProductData}/>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row className="confirm_form_section">
                  <Col md={12}>
                    <TermsAgreeFormElement/>
                    <Button
                      id="button-confirm"
                      className="btn submit-btn pull-right"
                      type="submit"
                      formMethod="POST"
                      >
                      Confirm Order
                    </Button>
                  </Col>
                  
                  
                  
                </Row>
              </Container>
            </Form>
            <Toaster
              toastOptions={{
                success: {
                  style: {
                    background: 'green',
                  },
                },
                error: {
                  style: {
                    background: 'rgba(245,65,55,1)',
                    color: '#fff',
                    fontFamily: "'IBM Plex Sans', sans-serif"
                  },
                },
              }}
            />
          </section>
        </div>
      );
  }
  
  
};
export default CheckOut;
