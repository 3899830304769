import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
  CardElement,
  PaymentMethodMessagingElement
} from "@stripe/react-stripe-js";
import { useMutation } from "react-query";
import PaymentIntent from "../Apis/PaymentIntent";
import {useCartContext} from "../Context/CartContext";


const StripePayment = ({intTotal, formData, selectedPaymentMethod, selectedShippingMethod}) => {
    
  const stripe = useStripe();
  const elements = useElements();
  const {productID} = useCartContext();
    const { mutate } = useMutation(PaymentIntent, {
        onSuccess: async (response) => {
            console.log(response);
            
            const {clientSecret} = await response;
            console.log(clientSecret);
            const { paymentIntent, error } = await stripe.confirmPayment({
              //`Elements` instance that was used to create the Payment Element
              elements,
              clientSecret,
              confirmParams: {
                return_url: 'https://3001-cs-465185249629-default.cs-asia-southeast1-yelo.cloudshell.dev/success/123',
              },
            });
        
            if (error) {
              console.log(error.message);
            } else {
              // Payment is successful, you can handle success logic here
              console.log("Payment successful:", paymentIntent);
            }
        },
        onError: (err) => {
            console.log(err);
        }
    });

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let payment_details = {
            amount: intTotal,
            currency: "usd",
            formData,
            selectedPaymentMethod, 
            selectedShippingMethod,
            product_ids: productID
    }   
    
    
    if (elements == null) {
      return;
    }
  
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }
    await mutate(payment_details);
  
    // Make a POST request to your server to create the PaymentIntent
    // const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/create-payment-intent`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     amount: intTotal, // Replace with the actual amount
    //     currency: "usd", // Replace with the desired currency
    //   }),
    // });

  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button type="submit" disabled={!stripe || !elements}>
        Pay
      </button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default StripePayment;
