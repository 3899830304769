import React from 'react'
import { AddPhoneRecordsData } from './AdminApis/DataApi';
import AddProductForm from './AdminComponent/AddProductForm';
const AddPhoneRecords = ()=>{
    const formFields = {
        phone_name: "",
        phone_model: "",
        phone_category: "",
        phone_imei: "",
        phone_condition: "New",
        phone_price: 25,
        phone_location: "",
        phone_availability: "",
        sold_by: "",
        sold_from: "",
        sales_date: null
    };
    
    return(
        <div className="App">
            <AddProductForm initialFormData={formFields} actionFunction={AddPhoneRecordsData} onSuccessPagePath="/admin/phones_record" />
        </div>
    )
}
export default AddPhoneRecords







