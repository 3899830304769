import React from "react";
import {useQuery} from 'react-query'
import newArrivalsData from "../Apis/NewArrivalsData";
import Table from "react-bootstrap/Table";
const NewArrivals = ()=>{
    const { data, isSuccess,isLoading,isError} = useQuery("newArrivalsData", () => newArrivalsData());
    
    if(isLoading){
        return <h1>Loading..</h1>
    }
    if(isError){
        return <h1>Error</h1>
    }
    if(isSuccess && data){
        const serverSideColumns = Object.keys(data.data[0]).map((key) => ({
            name: key,
            selector: key,
            sortable: true,
        }));
        const data_array = data.data
        return(
            <div className="App">
            <div className="container-xxl p-0">
                <div className="overflow-x-scroll">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                {serverSideColumns.map((item) => {
                                    return <th key={item.name}>{item.name}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {data_array.map((item, index) => {
                                return (
                                    <tr key={item.id}>
                                        {serverSideColumns.map((key) => (
                                            <td key={key.name}>{item[key.name]}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>

        
        );
    }
    
}
export default NewArrivals;