import React, {useState} from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {DealsData} from "../Apis/DealsData";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { UpdateDealsStatus, DeleteDealsProduct } from "./AdminApis/DataApi";
const Deals = () => {
    const { data, isSuccess, isLoading, isError } = useQuery("detalsData", () => DealsData());
    const queryClient = useQueryClient();

    const [deleteID, setDeleteID] = useState(null)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true); }

    const { mutate: updateStatus } = useMutation(UpdateDealsStatus, {
        onSuccess: (response) => {
            queryClient.invalidateQueries("detalsData");
        },
        onError: (err) => {
            console.log(err);
        }
    });
    const { mutate: deleteDeals } = useMutation(DeleteDealsProduct, {
        onSuccess: (response) => {
          handleClose();
          queryClient.invalidateQueries("detalsData");
        },
        onError: (err) => {
          console.log(err);
        }
    });
    async function delete_data(id) {
        await deleteDeals({ id: id })
    }

    if (isLoading) {
        return <h1>Loading..</h1>
    }
    if (isError) {
        return <h1>Error</h1>
    }
    if (isSuccess && data) {
        const data_column = Object.keys(data.data[0]).map((key) => ({
            name: key,
            selector: key,
            sortable: true,
        }));
        const data_array = data.data;
        return (
            <div className="App">
                <div className="container-xxl p-0">
                    <div className="overflow-x-scroll">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    {data_column.map((item) => (
                                        (item.name !== 'weekly_deal' && item.name !== 'product_status' && item.name !== 'url_heading' && item.name !== 'meta_description' && item.name !== 'full_description' && item.name !== 'short_description' && item.name !== 'metatag')? <th key={item.name}>{item.name}</th> : null
                                    ))}
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data_array.map((item, index) => {
                                    return (
                                        <tr key={item.id}>
                                            {data_column.map((key) => (
                                                (key.name !== 'weekly_deal' && key.name !== 'product_status' && key.name !== 'url_heading' && key.name !== 'meta_description' && key.name !== 'full_description' && key.name !== 'short_description' && key.name !== 'metatag') ? (<td key={key.name}>{item[key.name]}</td>):null
                                            ))}
                                            <td>
                                                <div className="d-flex" style={{ columnGap: "20px" }}>
                                                    <div className="actions">
                                                        <div className="d-flex" style={{ columnGap: "20px" }}>
                                                            <Button variant="success" href={`/admin/deals_edit/${item.id}`}>
                                                                Edit
                                                            </Button>
                                                            <Button variant="warning" onClick={() => {
                                                                updateStatus({ id: item.id, column_name: "product_status", new_status: item.product_status ? 0 : 1 })
                                                            }}>
                                                                {item.product_status ? "Hide" : "Show"}
                                                            </Button>
                                                            <Button variant="info" onClick={() => {
                                                                updateStatus({ id: item.id, column_name: "weekly_deal", new_status: item.weekly_deal ? 0 : 1 })
                                                            }}>
                                                                {item.weekly_deal ? "Active" : "Not Active"}
                                                            </Button>
                                                            <Button variant="danger" onClick={() => {
                                                                handleShow();
                                                                setDeleteID(item.id)
                                                            }}>
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm message...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this item</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                </Button>
                        <Button variant="danger" onClick={() => { delete_data(deleteID) }}>
                            Delete
                </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            // <DataTable
            //     noHeader
            //     columns={serverSideColumns}
            //     data={data.data}
            // />


        );
    }

}
export default Deals;