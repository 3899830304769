const ProductCategory = [
    {
        value: "Iphones",
        text: "Iphones"
    },
    {
        value: "Ipads",
        text: "Ipads"
    },
    {
        value: "Androids",
        text: "Androids"
    },
    {
        value: "Tablets",
        text: "Tablets"
    },
    {
        value: "Computers",
        text: "Computers"
    },
    {
        value: "Accessories",
        text: "Accessories"
    },
    {
        value: "Gadgets",
        text: "Gadgets"
    },
    {
        value: "Others",
        text: "Others"
    }
];

export default ProductCategory
