import React from 'react';
import "./AddressForm.css"
import { useCheckoutContext } from '../Context/CheckoutContext';

function AddressForm() {
  const { formData, setFormData } = useCheckoutContext();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="address">
      <div className="multiple-form-group">
        <div className="form-group">
          <label className="control-label" htmlFor="input-firstname">
            First Name
          </label>
          <input
            className={`form-control ${formData.firstname === false ? "border-danger" : ""}`}
            name="firstname"
            type="text"
            id="input-firstname"
            value={formData.firstname === false ? "" : formData.firstname}
            placeholder="First Name*"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="input-lastname">
            Last Name
          </label>
          <input
            type="text"
            id="input-lastname"
            name="lastname"
            value={formData.lastname === false ? "" : formData.lastname}
            className={`form-control ${formData.lastname === false ? "border-danger" : ""}`}
            placeholder="Last Name*"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="form-group">
        <label className="control-label" htmlFor="input-address">
          Address
        </label>
        <input
          type="text"
          id="input-address"
          name="address_1"
          value={formData.address_1 === false ? "" : formData.address_1}
          className={`form-control ${formData.address_1 === false ? "border-danger" : ""}`}
          placeholder="Address*"
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group">
        <label className="control-label" htmlFor="input-telephone">
          Mobile
        </label>
        <input
          type="tel"
          id="input-telephone"
          name="telephone"
          value={formData.telephone === false ? "" : formData.telephone}
          className={`form-control ${formData.telephone === false ? "border-danger" : ""}`}
          placeholder="Telephone*"
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group" htmlFor="input-email">
        <label className="control-label">Email</label>
        <input
          type="email"
          id="input-email"
          name="email"
          value={formData.email === false ? "" : formData.email}
          className={`form-control ${formData.email === false ? "border-danger" : ""}`}
          placeholder="E-Mail*"
          onChange={handleInputChange}
        />
      </div>

      <div className="multiple-form-group">
        <div className="form-group" htmlFor="input-city">
          <label className="control-label">City</label>
          <input
            type="text"
            id="input-city"
            name="city"
            value={formData.city === false ? "" : formData.city}
            className={`form-control ${formData.city === false ? "border-danger" : ""}`}
            placeholder="City*"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group" htmlFor="input-zone">
          <label className="control-label">Zone</label>
          <select
            name="zone_id"
            id="input-zone"
            className="form-control"
            value={formData.zone_id}
            onChange={handleInputChange}
          >
            <option value="322">Dhaka City</option>
            <option value="323">Khulna City</option>
            <option value="324">Rajshahi City</option>
            <option value="4231">Rangpur City</option>
            <option value="321">Chittagong City</option>
            <option value="4233">Gazipur City</option>
            <option value="4232">Others</option>
          </select>
        </div>
      </div>

      <div className="form-group">
        <label className="control-label">Comment</label>
        <textarea
          className="form-control"
          name="comment"
          value={formData.comment}
          placeholder="Comment"
          rows="6"
          onChange={handleInputChange}
        ></textarea>
      </div>
    </div>
  );
}

export default AddressForm;



