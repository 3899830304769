import React, { useEffect, useState } from "react";
import { useCheckoutContext } from "../Context/CheckoutContext";
import { useCartContext } from "../Context/CartContext";
const CheckoutInvoiceTable = ({ singleProductData }) => {
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const { productID } = useCartContext();
  const {selectedShippingMethod, setTotal : setTotalAmount} = useCheckoutContext();
  useEffect(() => {
    let calculatedSubTotal = 0;
    singleProductData.data.forEach((item) => {
      calculatedSubTotal += item.price * productID.quantity;
    });
    const deliveryCost = selectedShippingMethod === "flat" ? 60 : 0; 
    const calculatedTotal = calculatedSubTotal + deliveryCost;

    setSubTotal(calculatedSubTotal);
    setTotal(calculatedTotal);
    setTotalAmount(calculatedSubTotal)
  }, [singleProductData, productID,selectedShippingMethod]);
  return (
    <table className="checkout-data table-bordered">
      <thead>
        <tr>
          <th>Product Name</th>
          <th>Price</th>
          <th className="text-right">Total</th>
        </tr>
      </thead>
      <tbody>
        {singleProductData.data.map((item) => {

          return (
            <tr key={item.id}>
              <td className="name">
                <a href={`/products/${item.p_category}/${item.id}-${item.url_heading}`}>
                  {item.p_name}
                </a>
              </td>
              <td className="price">
                <span>{item.price}</span> <span> x </span>{" "}
                <span>{productID.quantity}</span>
              </td>
              <td className="price text-right">
                {item.price * productID.quantity}
              </td>
            </tr>
          );
        })}

        <tr className="total">
          <td colSpan="2" className="text-right">
            <strong>Sub-Total:</strong>
          </td>
          <td className="text-right">
            <span className="amount">{subTotal}</span>
          </td>
        </tr>
        <tr className="total">
          <td colSpan="2" className="text-right">
            <strong>Home Delivery:</strong>
          </td>
          <td className="text-right">
            <span className="amount">
              {selectedShippingMethod === "flat" ? 60 : null}
            </span>
          </td>
        </tr>
        <tr className="total">
          <td colSpan="2" className="text-right">
            <strong>Total:</strong>
          </td>
          <td className="text-right">
            <span className="amount">{total}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default CheckoutInvoiceTable;
