import axios from "axios";
const ProductDelete = async (data) => {
    try {
        const config = {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/product_delete`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
};
const UpdateProductStatus = async (data) => {
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/set_product_hide_show`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const ProductEdit = async (data) => {
    // /upload/file
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/process_edit_selected_products`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: data,
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const AddNewProduct = async (data) => {
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/process_add_products`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: data,
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}

// ============ deals apis ==============
const UpdateDealsStatus = async (data) => {
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/deal_data_status_update`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const DeleteDealsProduct = async (data) => {
    try {
        const config = {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/deals_delete`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
};
const AddNewDeal = async (data) => {
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/deal_data_insert`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: data,
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const UpdateDealsData = async (data) => {
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/deal_data_update`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: data,
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
// =============== Staff Api =============
const EmployeeDetails = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/employee_details`);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}
const SingleEmployeeDetails = async (employeeId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/employee_details/${employeeId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}
const UpdateEmployeeData = async (data) => {
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/employee_details_update`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const AddNewEmployee = async (data) => {
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/process_add_employee`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const DeleteEmployee = async (data)=>{
    try {
        const config = {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/employee_delete`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
// =============== Price List API ============
const PriceListData = async ()=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/a_price_list`);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}
const SinglePriceListData = async (productId)=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/a_price_list/${productId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}
const UpdatePriceListStatus = async (data)=>{
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/set_price_list_hide_show`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const UpdatePriceListData = async (data)=>{
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/pricelist_update`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const AddPriceListData = async (data)=>{
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/process_add_pricelist`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const DeletePriceList = async (data)=>{
    try {
        const config = {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/process_delete_pricelist`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
// =============== PHONE RECORDS START ==============
const PhoneRecordsData = async ()=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/phones_record`);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}
const SinglePhoneRecordsData = async (productID)=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/phones_record/${productID}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}
const UpdatePhoneRecordsData = async (data)=>{
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/process_edit_phone_record`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const AddPhoneRecordsData = async (data)=>{
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/process_add_pricelist`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const DeletePhoneRecords = async (data)=>{
    try {
        const config = {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/process_delete_phone_record`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
// ================= stores data 
const StoresData = async ()=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/stores`);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}

const SingleStoresData = async (storeID)=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/stores/${storeID}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}
const UpdateStoresData = async (data)=>{
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/process_edit_selected_store`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const AddStoresData= async (data)=>{
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/process_add_store`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const DeleteStoresData = async (data)=>{
    try {
        const config = {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/delete_store_data`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
// ================ Staff DATA ============
const StaffPriceListData = async ()=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/staff/price_list`);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}
const StaffPhoneRecords = async ()=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/staff/phone_records`);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}

const processSalesPhone = async (data)=>{
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/staff/process_sales_phone`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}

/** ========= Slider option ======== */
const addNewSlider = async (data)=>{
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/add_new_slider`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: data,
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
const singleSliderData = async (sliderID)=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/slider_data/${sliderID}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}
const deleteSlider = async (data)=>{
    try {
        const config = {
            method: "delete",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/delete_slider_data`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
} 
const updateSliderStatus = async (data)=>{
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/set_slider_hide_show`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
} 
const updateSliderData = async (data)=>{
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_URL}/admin/process_edit_slider_data`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: data,
        };

        const response = await axios(config);

        return response.data;
    } catch (error) {
        throw error;
    }
}
export { ProductDelete, UpdateProductStatus, ProductEdit, AddNewProduct, UpdateDealsStatus, DeleteDealsProduct, AddNewDeal, UpdateDealsData, EmployeeDetails, SingleEmployeeDetails, UpdateEmployeeData, AddNewEmployee, DeleteEmployee, PriceListData, SinglePriceListData, UpdatePriceListStatus, UpdatePriceListData, AddPriceListData, DeletePriceList, PhoneRecordsData, UpdatePhoneRecordsData, AddPhoneRecordsData, DeletePhoneRecords, SinglePhoneRecordsData, StoresData, DeleteStoresData, SingleStoresData, UpdateStoresData, AddStoresData, StaffPriceListData, StaffPhoneRecords, processSalesPhone, deleteSlider, updateSliderStatus, singleSliderData, addNewSlider, updateSliderData}
