import axios from "axios";

const AdminLogin = async (data) => {
  let path = null;
  if(data.login_as === "Admin"){
    path = "admin/login"
  }
  else if(data.login_as === "Staff"){
    path = "admin/staff/login"
  }
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_URL}/${path}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    const response = await axios(config);
    // console.log(response);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export {AdminLogin};