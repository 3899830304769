const ProductStatus = [
    {
        text: "Show",
        value: 1
    },
    {
        text: "Hide",
        value: 0
    }
]
export default ProductStatus


