import axios from "axios";
const DealsData = async ()=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/deal_data`);
        // console.log(response);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}
const SingleDealsData = async (productId)=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/deal_data/${productId}`);
        // console.log(response);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        throw error;
    }
}
export {DealsData, SingleDealsData};