import React from 'react';
// import { Helmet } from "react-helmet";
import { Container } from 'react-bootstrap';
import './ThankYou.css';
const ThankYou = () => {
    return (
        <div className="App">
            {/* <Helmet>
                <link rel="stylesheet" href="/Core.css" />
            </Helmet> */}
            <Container>
            <div id="wizard-checkout" class="wizard-checkout-confirm wizard-icons wizard-icons-example">
            <div id="checkout-confirmation" className="content fv-plugins-bootstrap5 fv-plugins-framework active dstepper-block">
                <div className="row mb-3">
                    <div className="col-12 col-lg-8 mx-auto text-center mb-3">
                        <h4 className="mt-2">Thank You! 😇</h4>
                        <p>Your order <a href="javascript:void(0)">#1536548131</a> has been placed!</p>
                        <p>We sent an email to <a href="mailto:john.doe@example.com">john.doe@example.com</a> with your order confirmation and receipt. If the email hasn't arrived within two minutes, please check your spam folder to see if the email was routed there.</p>
                        <p><span className="fw-medium"><i className="ti ti-clock me-1"></i> Time placed:&nbsp;</span> 25/05/2020 13:35pm</p>
                    </div>
                    {/* Confirmation details */}
                    <div className="col-12">
                        <ul className="list-group list-group-horizontal-md">
                            <li className="list-group-item flex-fill p-4 text-heading">
                                <h6 className="d-flex align-items-center gap-1"><i className="ti ti-map-pin"></i> Shipping</h6>
                                <address className="mb-0">
                                    John Doe <br />
                4135 Parkway Street,<br />
                Los Angeles, CA 90017,<br />
                USA
              </address>
                                <p className="mb-0 mt-3">
                                    +123456789
              </p>
                            </li>
                            <li className="list-group-item flex-fill p-4 text-heading">
                                <h6 className="d-flex align-items-center gap-1"><i className="ti ti-credit-card"></i> Billing Address</h6>
                                <address className="mb-0">
                                    John Doe <br />
                4135 Parkway Street,<br />
                Los Angeles, CA 90017,<br />
                USA
              </address>
                                <p className="mb-0 mt-3">
                                    +123456789
              </p>
                            </li>
                            <li className="list-group-item flex-fill p-4 text-heading">
                                <h6 className="d-flex align-items-center gap-1"><i className="ti ti-ship"></i> Shipping Method</h6>
                                <p className="fw-medium mb-3">Preferred Method:</p>
              Standard Delivery<br />
              (Normally 3-4 business days)
            </li>
                        </ul>
                    </div>
                </div>

                <div className="row">
                    {/* Confirmation items */}
                    <div className="col-xl-9 mb-3 mb-xl-0">
                        <ul className="list-group">
                            <li className="list-group-item p-4">
                                <div className="d-flex gap-3">
                                    <div className="flex-shrink-0">
                                        <img src="../../assets/img/products/1.png" alt="google home" className="w-px-75" />
                                    </div>
                                    <div className="flex-grow-1">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <a href="javascript:void(0)" className="text-body">
                                                    <p>Google - Google Home - White</p>
                                                </a>
                                                <div className="text-muted mb-1 d-flex flex-wrap"><span className="me-1">Sold by:</span> <a href="javascript:void(0)" className="me-3">Apple</a> <span className="badge bg-label-success">In Stock</span></div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="text-md-end">
                                                    <div className="my-2 my-lg-4"><span className="text-primary">$299/</span><s className="text-muted">$359</s></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item p-4">
                                <div className="d-flex gap-3">
                                    <div className="flex-shrink-0">
                                        <img src="../../assets/img/products/2.png" alt="google home" className="w-px-75" />
                                    </div>
                                    <div className="flex-grow-1">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <a href="javascript:void(0)" className="text-body">
                                                    <p>Apple iPhone 11 (64GB, Black)</p>
                                                </a>
                                                <div className="text-muted mb-1 d-flex flex-wrap"><span className="me-1">Sold by:</span> <a href="javascript:void(0)" className="me-3">Apple</a> <span className="badge bg-label-success">In Stock</span></div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="text-md-end">
                                                    <div className="my-2 my-lg-4"><span className="text-primary">$299/</span><s className="text-muted">$359</s></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* Confirmation total */}
                    <div className="col-xl-3">
                        <div className="border rounded p-4 pb-3">
                            {/* Price Details */}
                            <h6>Price Details</h6>
                            <dl className="row mb-0">
                                <dt className="col-6 fw-normal text-heading">Order Total</dt>
                                <dd className="col-6 text-end">$1198.00</dd>
                                <dt className="col-sm-6 text-heading fw-normal">Delivery Charges</dt>
                                <dd className="col-sm-6 text-end"><s className="text-muted">$5.00</s> <span className="badge bg-label-success ms-1">Free</span></dd>
                            </dl>
                            <hr className="mx-n4" />
                            <dl className="row mb-0">
                                <dt className="col-6 text-heading">Total</dt>
                                <dd className="col-6 fw-medium text-end text-heading mb-0">$1198.00</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </Container>
        </div>
    )
}
export default ThankYou

