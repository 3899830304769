import React, { createContext, useContext, useState } from "react";

const CheckoutContext = createContext();

export const useCheckoutContext = () => {
  return useContext(CheckoutContext);
};

export const CheckoutProvider = ({ children }) => {
  // Define the shared state here
  const [formData, setFormData] = useState({firstname: '',lastname: '',address_1: '', telephone: '',email: '',city: '',zone_id: '322',comment: '',});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cod");
  const [selectedShippingMethod, setSelectedShippingMethod] = useState("flat");
  const [agree, setAgree] = useState(false);
  const [total, setTotal] = useState(0)
    // console.log(formData, selectedPaymentMethod, selectedShippingMethod, agree);
  return (
    <CheckoutContext.Provider
      value={{
        total,
        setTotal,
        formData,
        setFormData,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        selectedShippingMethod,
        setSelectedShippingMethod,
        agree,
        setAgree,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
