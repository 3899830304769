import React from "react";
import { AddNewProduct } from "./AdminApis/DataApi";
import AddProductForm from "./AdminComponent/AddProductForm";
const AddProduct = () => {
    const initialFormData = {
        product_id: null,
        p_name: "",
        p_brand: "",
        p_condition: "Used-Grade A", // Default value for select
        p_color: "",
        p_category: "Iphones", // Default value for select
        mrp: 0,
        price: 0,
        p_image: null,
        image_alternate: "",
        short_description: "",
        full_description: "",
        p_status: 1, // Default value for select
        url_heading: "",
        meta_desc: "",
        meta_tag: "",
    };
  return (
    <div className="App">
        <AddProductForm initialFormData={initialFormData} actionFunction={AddNewProduct} onSuccessPagePath="/admin/dashboard" />
    </div>
  );
};

export default AddProduct;
