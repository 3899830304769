import React, {useState} from 'react';
import Table from "react-bootstrap/Table";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {StaffPhoneRecords, processSalesPhone } from './AdminApis/DataApi';
import { useQuery, useMutation, useQueryClient  } from 'react-query';
const StaffPhoneRecordsPage = ()=>{
    const {
        data,
        isLoading,
        isError,
    } = useQuery("staffPhoneRecords",StaffPhoneRecords);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedColumn, setSelectedColumn] = useState({id: 102, phone_availability: "avaiable"});
    const today = new Date(); // Get the current date
    const year = today.getFullYear(); // Get the current year
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Get the current month (0-indexed, so add 1)
    const day = String(today.getDate()).padStart(2, '0'); 
    const userDataString = localStorage.getItem('user');
    const userData = JSON.parse(userDataString);
    const queryClient = useQueryClient();
    const { mutate } = useMutation(processSalesPhone, {
        onSuccess: (response) => {
            queryClient.invalidateQueries("staffPhoneRecords");
            handleClose()
        },
        onError: (err) => {
            console.log(err);
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        let body_data= {
            id: selectedColumn.id,
            phone_availability: "Sold",
            sold_from: selectedColumn.phone_location,
            sold_by: userData.user_address,
            sales_date: `${year}-${month}-${day}`
        }
        mutate(body_data)

    };
    if (isLoading) {
        return <h1>Loading...</h1>
    }
    if (isError) {
        return <h1>Error...</h1>
    }
    if (data) {
        
        
        const data_column = Object.keys(data.data[0]).map((key) => ({
            name: key,
            selector: key,
            sortable: true,
        }));
        const data_array = data.data;
        return (
            <div className="App">
                <div className="container-xxl p-0">
                    <div className="overflow-x-scroll">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    {data_column.map((item) => {
                                        return <th key={item.name}>{item.name}</th>;
                                    })}
                                    <th>Actions</th>
                                </tr>
                                
                            </thead>
                            <tbody>
                                {data_array.map((item, index) => {
                                    return (
                                        <tr key={item.id}>
                                            {data_column.map((key) => (
                                                <td key={key.name}>{item[key.name]}</td>
                                            ))}
                                            <td>
                                                <Button variant="success" 
                                                onClick={() => {
                                                    handleShow();
                                                    setSelectedColumn(item);}}>
                                                    Sell
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                                
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Form onSubmit={handleSubmit}>
                        {
                            (selectedColumn) ? (
                                <Modal.Body>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={selectedColumn.phone_name}
                                        name="phone_name"
                                        disabled
                                    />
                                    <Form.Label>Model</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={selectedColumn.phone_model}
                                        name="phone_model"
                                        disabled
                                    />
                                    <Form.Label>Store Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={selectedColumn.phone_location}
                                        name="phone_location"
                                        disabled
                                    />
                                    <Form.Label>Sales Person Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={userData.user_address}
                                        name="user_address"
                                        disabled
                                    />
                                    <Form.Label>Sales Date</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={`${year}-${month}-${day}`}
                                        name="sales_date"
                                        disabled
                                    />
                                </Modal.Body>

                            ) : null
                            
                        }
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Close</Button>
                            <Button variant="primary" type="submit">Save changes</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
               
            </div>
        )
    }
}
export default StaffPhoneRecordsPage




