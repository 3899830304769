import React from 'react';
import AddProductForm from './AdminComponent/AddProductForm';
import { useQuery } from 'react-query';
import { SinglePhoneRecordsData, UpdatePhoneRecordsData } from './AdminApis/DataApi';
import { useParams } from 'react-router-dom';
const PhoneRecordsEdit = () => {
    const { id } = useParams();
    const {
        data,
        isLoading,
        isError,
    } = useQuery("singlePhoneRecord", () => SinglePhoneRecordsData(id));
    if (isLoading) {
        return <h1>Loding..</h1>
    }
    if (isError) {
        return <h1>Error..</h1>
    }
    if (data) {
        const defultData = data.data[0]
        return (
            <div className="App">
                <AddProductForm actionFunction={UpdatePhoneRecordsData} initialFormData={defultData} onSuccessPagePath="/admin/phones_record" />
            </div>
        )
    }
}
export default PhoneRecordsEdit;

