import React, {useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import Header from "./component/Header/header";
import { Container } from "react-bootstrap";
import "./ProductDetails.css";
import QuickMapViewSection from "./component/MapCard/QuickMapViewSection";
import Footer from "./component/footer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Review from "./component/Review";
import SectionHeading from "./component/sectionHeading";
import { SingleProduct, SingleProductList } from "./Apis/SingleProduct";
import RelatedProduct from "./component/RelatedProduct";
import ThreeDParticleScene from "./component/ThreeDParticleScene";
import { IconPlus,IconMinus } from '@tabler/icons-react';
import { useCartContext } from "./Context/CartContext";
const ProductDetails = () => {
  const { category, productid } = useParams();
  const navigate = useNavigate()
  const {setProductID} = useCartContext();
  const productIDParts = productid.split("-");
    const [quantity, setQuantity] = useState(1);
  
    const handleIncrement = () => {
      setQuantity(quantity + 1);
    };
  
    const handleDecrement = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    };
    const addProductOnCart = ()=>{
      setProductID({productid: productIDParts[0], quantity});
      navigate("/checkout")
    }
  const {
    data: singleProductData,
    isFetched: isSingleProductFetched,
    isSuccess: isSingleProductSuccess,
    isLoading: isSingleProductLoading,
    isError: isSingleProductError,
  } = useQuery("singleProductData", () => SingleProduct(productIDParts[0]));

  const {
    data: productListData,
    isFetched: isProductListFetched,
    isSuccess: isProductListSuccess,
    isLoading: isProductListLoading,
  } = useQuery(
    "singleProductListData",
    () => SingleProductList(category, productIDParts[0]) // Replace with your second API call function
  );
  if (isSingleProductLoading || isProductListLoading) {
    return <p>Loading...</p>;
  }

  if (isSingleProductError) {
    return <p>Error fetching data</p>;
  }
  if (isSingleProductSuccess && isSingleProductFetched) {
    let product_details = singleProductData.data[0];
    return (
      <div className="App">
        <ThreeDParticleScene />
        <Header />
        <section className="product_details_section">
          <Container>
            <div className="details_content flex">
              <div className="image_section">
                <img
                  src={`${process.env.REACT_APP_FRONTEND_DATA_URL}/${product_details.p_image}`}
                  alt={product_details.image_alternate || "single product"}
                />
              </div>
              <div className="product_details">
                <h3>{product_details.p_name}</h3>
                <div className="product_key_list">
                  <ul className="flex">
                    {product_details.mrp &&
                    product_details.price &&
                    product_details.mrp > product_details.price ? (
                      <li>
                        <p>
                          Save: £
                          {(
                            product_details.mrp - product_details.price
                          ).toFixed(2)}
                        </p>
                      </li>
                    ) : null}
                    {product_details.p_status ? (
                      <li>
                        <p>
                          Status:
                          <span style={{ color: "#e5330b" }}>In Stock</span>
                        </p>
                      </li>
                    ) : null}
                  </ul>
                </div>

                <div className="short_description">
                  <ul>
                    {product_details.p_condition ? (
                      <li>
                        <p>
                          Condition: <span>{product_details.p_condition}</span>
                        </p>
                      </li>
                    ) : null}
                    {product_details.p_color ? (
                      <li>
                        <p>
                          Color: <span>{product_details.p_color}</span>
                        </p>
                      </li>
                    ) : null}

                    <li>
                      <div className="deal-specification">
                        Price:{" "}
                        {product_details.price < product_details.mrp ? (
                          <>
                            <span
                              style={{
                                color: "var(--text-color)",
                                fontWeight: "bold",
                              }}
                            >
                              £{product_details.price}
                            </span>
                            <span
                              style={{
                                color: "var(--text-color)",
                                fontWeight: "bold",
                              }}
                            >
                              <em> was </em>
                            </span>
                            <span
                              style={{
                                textDecoration: "line-through",
                                color: "red",
                              }}
                            >
                              £{product_details.mrp}
                            </span>
                          </>
                        ) : (
                          <span
                            style={{
                              color: "var(--color-text)",
                              fontWeight: "bold",
                            }}
                          >
                            £{product_details.price}
                          </span>
                        )}
                      </div>
                    </li>
                    <li>
                      <a href="#">View More Info</a>
                    </li>
                    <li>
                      <div className="buy_now_section">
                        <div className="cart-option flex">
                          <label className="quantity">
                            <span className="ctl" onClick={handleDecrement}>
                              <IconMinus/>
                            </span>
                            <span className="qty">
                              <input
                                type="text"
                                name="quantity"
                                id="input-quantity"
                                value={quantity}
                                size="2"
                                readOnly
                              />
                            </span>
                            <span
                              className="ctl increment"
                              onClick={handleIncrement}
                            >
                              <IconPlus/>
                            </span>
                            <input
                              type="hidden"
                              name="product_id"
                              value="29930"
                            />
                          </label>
                          <button
                            id="button-cart"
                            className="btn submit-btn"
                            data-loading-text="Loading..."
                            onClick={addProductOnCart}
                          >
                            Buy Now
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <h3 className="review_heading">Review</h3>
                <OwlCarousel
                  className="owl-theme"
                  margin={10}
                  items={1.2}
                  dots={false}
                  loop={false}
                  responsiveClass
                  nav={false}
                >
                  <div className="item">
                    <Review />
                  </div>
                  <div className="item">
                    <Review />
                  </div>
                  <div className="item">
                    <Review />
                  </div>
                </OwlCarousel>
              </div>
            </div>
            {product_details.full_description ? (
              <div className="details_specification">
                <h3>Specification</h3>
                <p>{product_details.full_description}</p>
              </div>
            ) : null}
          </Container>
        </section>
        <section className="related_products">
          <div className="text-center related_product_heading">
            <SectionHeading text={`Related ${category}`} />
          </div>
          {isProductListFetched && isProductListSuccess ? (
            <RelatedProduct productList={productListData}></RelatedProduct>
          ) : null}
        </section>
        <QuickMapViewSection />
        <Footer></Footer>
      </div>
    );
  }
};
export default ProductDetails;
