import React, {useEffect} from "react";
import { useCheckoutContext } from "../Context/CheckoutContext";
import { Form } from "react-bootstrap";
const TermsAgreeFormElement = ()=>{
    const {agree, setAgree} = useCheckoutContext();
    const handleAgreeChange = () => {
        setAgree(!agree);
    };
    return(
        <div className="form_element">
            <div className="agree-text" style={{ marginBottom: "10px" }}>
            <Form.Group className="mb-3">
              
            </Form.Group>
                  <Form.Check
                    type="checkbox"
                    name="agree"
                    value="1"
                    checked={agree}
                    onChange={handleAgreeChange}
                  />
                  I have read and agree to the{" "}
                  <a href="https://www.startech.com.bd/warranty-policy" target="_blank">
                    <b>Terms and Conditions</b>
                  </a>
                  ,{" "}
                  <a href="https://www.startech.com.bd/privacy" target="_blank">
                    <b>Privacy Policy</b>
                  </a>
                  , and{" "}
                  <a href="https://www.startech.com.bd/refund-policy"target="_blank">
                    <b>Refund and Return Policy</b>
                  </a>
                  
                </div>

        </div>
    )
} 

export default TermsAgreeFormElement;


