import React from "react";
import SectionHeading from "./sectionHeading";
import RepairingProcessCard from "../RepairingProcessCard";
import './repairingProcess.css'
const RepairingProcess = () => {
  const repairingProcessArray = [
    {
      title: "Figure Out",
      imageSrc: "broken-screen.png",
      alt: "cracked-screen",
      steps: [
        "Figure out everything that’s gone wrong (test all your buttons, wifi, bluetooth, speakers – some faults aren’t obvious)",
        "Back your device up if you can (most of our repairs do not require your device to be wiped – but we can’t be held responsible if any data is lost)",
      ],
    },
    {
      title: "Bring it",
      imageSrc: "man-with-damage-mobile.jpg",
      alt: "damage-mobile",
      steps: [
        "Bring it in to your nearest store",
        "We will diagnose all faults, and agree with you what repairs are needed, and at what cost.",
        "Multiple repairs come with generous discounts… Try and get all your faults fixed at the same time.",
      ],
    },
    {
      title: "We fix it",
      imageSrc: "technician-repairing.jpg",
      alt: "technician repairing",
      steps: [
        "Most Repairs completed within 60 Minutes",
        "We will not carry out any additional work without your consent",
        "Top of the range equipment, tools, and Grade A parts.",
      ],
    },
    {
      title: "Post Care",
      imageSrc: "after-repair.jpg",
      alt: "mobile-after-repair",
      steps: [
        "6 Months Warranty with all repairs",
        "Great discounts on protective cases and other accessories when purchased with a repair",
        "Leave us a review to receive a free Gold Card membership – giving you and your family hefty discounts off all future services.",
      ],
    },
  ];
  return (
    <section className="work_section text-center full_container">
      <SectionHeading text="Repairing Service"></SectionHeading>

      <div className="service_description_card_group flex justify-center">
        {repairingProcessArray.map((item) => {
          return (
            <RepairingProcessCard processImage={item.imageSrc} processImageAlt={item.alt} processTitle={item.title} processSteps={item.steps}></RepairingProcessCard>
          );
        })}
      </div>
    </section>
  );
};
export default RepairingProcess;
