import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ProductsData from "../Apis/ProductsData";
import { ProductDelete, UpdateProductStatus } from "./AdminApis/DataApi";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {

  const [deleteID, setDeleteID] = useState(null)

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => { setShow(true); }

  const navigate = useNavigate();
  const { data, isSuccess, isLoading, isError } = useQuery(
    "mainProductData",
    () => ProductsData()
  );
  const queryClient = useQueryClient();

  const { mutate: deleteProduct } = useMutation(ProductDelete, {
    onSuccess: (response) => {
      handleClose();
      queryClient.invalidateQueries("mainProductData");
    },
    onError: (err) => {
      console.log(err);
    }
  });
  const { mutate: updateStatus } = useMutation(UpdateProductStatus, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("mainProductData");
    },
    onError: (err) => {
      console.log(err);
    }
  });

  const handleClick = (id) => {
    navigate(`/admin/main_product_edit/${id}`);
  };
  const updateProductStatus = async (p_id) => {
    updateStatus({ p_id: p_id })
  }
  async function delete_data(id) {
    await deleteProduct({ id: id })
  }
  if (isLoading) {
    return <h1>Loading</h1>;
  }
  if (isError) {
    return <h1>Data not found</h1>;
  }
  if (data && isSuccess) {
    const data_column = Object.keys(data.data[0]).map((key) => ({
      name: key,
      selector: key,
      sortable: true,
    }));


    // const data_column = data.data;
    const data_array = data.data;
    return (
      <div className="App">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="container-xxl p-0">
          <div>
            <div className="overflow-x-scroll">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {data_column.map((item) => (
                        (item.name !== 'meta_desc' && item.name !== 'meta_tag' && item.name !== 'url_heading' && item.name !== 'p_status' && item.name !== 'full_description' && item.name !== 'short_description')? <th key={item.name}>{item.name}</th> : null
                    ) 
                    )}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data_array.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        {data_column.map((key) => (
                            (key.name !== 'meta_desc' && key.name !== 'meta_tag' && key.name !== 'url_heading' && key.name !== 'p_status' && key.name !== 'full_description' && key.name !== 'short_description') ? (<td key={key.name}>{item[key.name]}</td>):null
                          
                        ))}
                        <td>
                          <div className="d-flex" style={{ columnGap: "20px" }}>
                            <div className="actions">
                              <div className="d-flex" style={{ columnGap: "20px" }}>
                                <Button variant="success" onClick={() => { handleClick(item.id) }}>
                                  Edit
                                </Button>
                                <Button variant="warning" onClick={() => {
                                  updateProductStatus(item.product_id)
                                }}>
                                  {item.p_status ? "Hide" : "Show"}
                                </Button>
                                <Button variant="danger" onClick={() => {
                                  handleShow();
                                  setDeleteID(item.id)
                                }}>
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm message...</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to delete this item</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="danger" onClick={() => { delete_data(deleteID) }}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
};
export default Dashboard;
