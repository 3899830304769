import axios from "axios";
const Slider = async ()=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/slider_data`);
        // console.log(response);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        // You can choose to handle errors here, such as returning an empty array or re-throwing the error.
        throw error;
    }
}
export default Slider;