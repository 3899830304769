import React from 'react';
import AddProductForm from './AdminComponent/AddProductForm';
import { addNewSlider } from './AdminApis/DataApi';
const AddSlider = ()=>{
    const formFields = {
        heading: "",
        subheading: "",
        p_image: "",
        p_status: 1
    };
    
    
    return(
        <div className="App">
            <AddProductForm initialFormData={formFields} actionFunction={addNewSlider} onSuccessPagePath="/admin/slider_controller" />
        </div>
    )
}
export default AddSlider



