import React from 'react';
import AddProductForm from './AdminComponent/AddProductForm';
import { AddNewEmployee } from './AdminApis/DataApi';
const AddStaff = () => {
    const formFields = {
        name_s: "",
        username_s: "",
        password_s: "",
        telephone_s: "",
        address_s: "",
        more_info: ""
    };
    return (
        <div className="App">
            <AddProductForm actionFunction={AddNewEmployee} initialFormData={formFields} onSuccessPagePath="/admin/employee_details" />
        </div>
    )
}
export default AddStaff;




