import React from "react";
import "./sectionHeading.css";
const SectionHeading = ({text})=>{
    return(
        <div className="heading_container">
            <h1>
              <span>{text}</span>
            </h1>
          </div>
    )
}
export default SectionHeading;