import React from "react";
import { Container, Row } from "react-bootstrap";
import "./ContactUsMapCard.css";
const ContactUsMapCard = ({
  mapUrl,
  locationName,
  address,
  phone,
  mobile,
  whatsapp,
  email,
}) => {
  let formattedPhoneNumber;
  if (whatsapp) {
    formattedPhoneNumber = whatsapp.replace(/\D/g, "");
  }
  return (
    <section className="map_card">
      <Container>
        <Row>
          <div className="map_text_section">
            <h2>{locationName}</h2>
            <h3>{address}</h3>
            <ul>
              {phone ? (
                <li>
                  Tel: <a href={`tel:${phone.replace(/\s/g, "")}`}>{phone}</a>
                </li>
              ) : null}
              {mobile ? (
                <li>
                  Mobile:
                  <a href={`tel:${mobile.replace(/\s/g, "")}`}>{mobile}</a>
                </li>
              ) : null}

              {formattedPhoneNumber ? (
                <li>
                  Whatsapp:
                  <a href={`https://wa.me/${formattedPhoneNumber}`}>
                    {whatsapp}
                  </a>
                </li>
              ) : null}
              {formattedPhoneNumber ? (
                <li>
                  Email: <a href={`mailto:${email}`}>{email}</a>
                </li>
              ) : null}
            </ul>
          </div>
          <div className="map_view_section">
            <div className="image_box">
              <iframe
                title={locationName}
                className="responsive-iframe"
                src={mapUrl}
              ></iframe>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};
export default ContactUsMapCard;
