import React from 'react';
import { useQuery } from 'react-query';
import { StoresData } from '../AdminApis/DataApi';
import { Form } from "react-bootstrap";
const LocationDropDown = ({currentValue,name, handleChange})=>{
    const {
        data,
        isLoading,
        isError,
    } = useQuery("storesData",StoresData);
    if (isLoading) {
        return <h1>Loding..</h1>
    }
    if (isError) {
        return <h1>Error..</h1>
    }
    if(data){
        console.log();
        const listData = data.data
        
        return(
            <Form.Select name={name} value={currentValue} onChange={handleChange}>
                <option value={null}></option>
                {
                    listData.map((item) => (<option key={item.id} value={item.store_name}>{item.store_name}</option>))
                }
            </Form.Select>
        )

    }
   
}
export default LocationDropDown

