import React from "react"
import RelatedProduct from "../RelatedProduct"
const ProductListSection = ({title, productList})=>{
    return(
        <section className="product_section text-center full_container">
        <div className="heading_container">
          <h1>
            <span>{title}</span>
          </h1>
        </div>
        <div className="product_group">
            <RelatedProduct productList={productList}/>
          
        </div>
        
        <div className="center_btn">
          <div className="view_more_btn">
            <a href="/products" className="">More Deals</a>
          </div>
        </div>
        
      </section>
    )
}
export default ProductListSection


