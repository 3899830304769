import React from "react";
import Header from "./component/Header/header";
import HeaderList from "./component/ProductCatList/HeaderList";
import SectionHeading from "./component/sectionHeading";
import './Products.css';
import QuickMapViewSection from "./component/MapCard/QuickMapViewSection";
import Footer from "./component/footer";
import { useQuery } from "react-query";
import ProductsData from "./Apis/ProductsData";
import RelatedProduct from "./component/RelatedProduct";
import ThreeDParticleScene from "./component/ThreeDParticleScene";
const Products = ()=>{
    const { data, isFetched, isSuccess,isLoading,isError} = useQuery("productdata", () => ProductsData());
    if (isLoading) {
        return <p>Loading...</p>;
      }
    
      if (isError) {
        return <p>Error fetching data</p>;
      }
    if(isSuccess && isFetched){
    return(
        <div className="App">
            <ThreeDParticleScene/>
            <Header/>
            <div className="cate_list">
                <HeaderList/>
            </div>
            <section className="product_list_section">
                <div className="text-center ">
                    <SectionHeading text="Buy All Ipads in your Budget"></SectionHeading>
                    <h2>We sell all models of New, Refrubished and Used Ipads</h2>
                </div>
                <RelatedProduct productList={data}></RelatedProduct>
            </section>
            <QuickMapViewSection/>
            <Footer></Footer>
        </div>
    );
}
}
export default Products;