import React from "react";
import SectionHeading from "../sectionHeading";
import QuickViewCard from "./QuickViewCard";
import { useQuery } from "react-query";
import Stores from "../../Apis/stores";
import "./QuickMapViewSection.css";
const QuickMapViewSection = () => {
  const { data, isFetched, isSuccess,isLoading,isError } = useQuery("stores", () => Stores());


  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error fetching data</p>;
  }

  if (isSuccess && isFetched) {
    const map_details_arr = data.data
    return (
      <section className="map_section">
        <div className="full_container m-auto">
          <div className="text-center">
            <SectionHeading text="Our Stores and Repairing Centers"></SectionHeading>
          </div>

          <div className="map_layout_section flex justify-center flex-wrap">
            {isSuccess && map_details_arr.map((item) => (
                item.map_status ? (
                    <QuickViewCard
                        key={item.id}
                        mapUrl={item.map_link}
                        locationName={item.store_name}
                        address={item.store_address}
                        phone={item.store_phone}
                        mobile={item.store_mobile}
                        email={item.store_email}
                        whatsapp={item.whatsapp_number}
                    />

                ) : null
              
            ))}
          </div>
        </div>
      </section>
    );
  }
  
};
export default QuickMapViewSection;
