import React from "react";
const RepairItemsCard = ({repairItemImage, repairItemImageAlt, repairItemText, className}) => {
  return (
    <div className={`cart repair-main-cart ${className ? className : ''}`}>
      <img
        src={`${process.env.REACT_APP_FRONTEND_DATA_URL}/${repairItemImage}`}
        alt={repairItemImageAlt}
      />
      <p className="card-text text-center">{repairItemText}</p>
    </div>
  );
};
export default RepairItemsCard;
