import axios from "axios";
const SingleProduct = async (productId)=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/single_product/${productId}`);
        // console.log(response);
        return response.data;
    } catch (error) {
        // console.error("Error fetching stores:", error);
        console.log(error);
        // return new Error(error);
    }
}
const SingleProductList = async (productCategory,currentProductID)=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/single_product_list/${productCategory}/${currentProductID}`);
        // console.log(response);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        // You can choose to handle errors here, such as returning an empty array or re-throwing the error.
        return error;
    }
}
const SingleProductListNoFilter = async (category)=>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/category/${category}`);
        // console.log(response);
        return response.data;
    } catch (error) {
        console.error("Error fetching stores:", error);
        // You can choose to handle errors here, such as returning an empty array or re-throwing the error.
        return error;
    }
}
export {SingleProduct, SingleProductList, SingleProductListNoFilter};





