// ** React Imports
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {  Row,
    Col,
    // CardTitle,
    // CardText,
    Form,
    // Label,
    // Input,
    Button,} from "react-bootstrap";
// ** Icons Imports
// import { Facebook, Twitter, Mail, GitHub } from "react-feather";

// ** Custom Components
import InputPasswordToggle from "./AdminComponent/input-password-toggle"

// ** Reactstrap Imports
import {
    // Row,
    // Col,
    CardTitle,
    CardText,
    // Form,
    Label,
    Input,
    // Button,
} from "reactstrap";
import { Helmet } from "react-helmet";


// ** Illustrations Imports
import source from './Images/login-v2.svg';
// ** Styles
import "./Login.css";
import { useMutation } from 'react-query';
import {AdminLogin} from "./AdminApis/LoginAdmin";
import toast, { Toaster } from 'react-hot-toast';
import { useUser} from '../Context/AdminContext';
const Login = () => {
    function isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    }
    const { login: contextLogin, user: contextUser } = useUser();
    const navigate = useNavigate()
    const [formData, setFormData] = useState({ user_address: "", password: "", login_as: "" });

    const { mutate, isLoading, } = useMutation(AdminLogin, {
        onSuccess: (response) => {
            console.log(response);
            
            if(response.length){
                const userData = { user_address: formData.user_address, login_as: formData.login_as };
                // console.log(userData);
                // console.log(contextUser);
                
                localStorage.setItem('user', JSON.stringify(userData))
                // navigate('/admin/dashboard');
                contextLogin(userData); // Set the user data in the context
                if(userData.login_as == "Staff"){
                    navigate('/admin/staff_entrypoint');
                }
                else if(userData.login_as == "Admin"){
                    navigate('/admin/dashboard');
                }

                
            }else{
                setFormData({email: false, password: false, login_as: ""})
            }

        },
        onError: (err)=>{
            // isError = true
            setFormData({email: false, password: false, login_as: ""})
            toast.error('Data Not Found', {
                duration: 2000, // Duration in milliseconds
            })

        }
    })

    const login = async (e) => {
        e.preventDefault()
        if (!formData.user_address || !formData.password || !formData.login_as) {
            return false;
        }
        await mutate(formData);

        // localStorage.setItem('user', 'test')
        // navigate('/admin/dashboard')
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    // if(isLoading){
    //     return <h1>Loading...</h1>
    // }
    // if(isError){
    //     return <h1>error...</h1>
        
    // }

    return (
        <div className="App auth-wrapper auth-cover">
            <Helmet>
                <link  rel="stylesheet" href="/Core.css" />
            </Helmet>
            <Row className="auth-inner m-0">
                {/* <Link className="brand-logo" to="/" onClick={(e) => e.preventDefault()}>

                    <h2 className="brand-text text-primary ms-1">
                        <div className="app_logo_section flex align-center relative">
                            <img
                                src="https://mytechdoctor.co.uk/assets/main_images/techdoctor_logo.png"
                                className="App-logo"
                                alt="logo"
                            />
                            <span style={{ fontWeight: "bold", color: "cyan" }}> TECH </span>
                            <span style={{ fontSize: "25px", color: "#fd9170" }}>DOCTOR</span>
                        </div>
                    </h2>
                </Link> */}
                <Col className="d-none d-lg-flex align-items-center p-5" lg="8" sm="12">
                    <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
                        <img className="img-fluid" src={source} alt="Login Cover" />
                    </div>
                </Col>
                <Col
                    className="d-flex align-items-center auth-bg px-2 p-lg-5"
                    lg="4"
                    sm="12"
                >
                    <Col className="px-xl-2 mx-auto" sm="8" md="6" lg="12">
                        {/* <CardTitle tag="h2" className="fw-bold mb-1">
              Welcome to Vuexy! 👋
            </CardTitle> */}
                        <CardText className="mb-2">
                            Please sign-in to your account and start the adventure
            </CardText>
                        <Form
                            className="auth-login-form mt-2"
                            onSubmit={login}
                        >
                            <div className="mb-1">
                                <Label className="form-label" for="login-email">
                                    Email
                                </Label>
                                <Input
                                    type="text"
                                    id="login-email"
                                    placeholder="john@example.com"
                                    name="user_address"
                                    className={`${formData.email === false ? "border-danger" : ""}`}
                                    value={formData.email === false ? "" : formData.email}
                                    onChange={handleInputChange}
                                    autoFocus
                                />
                            </div>
                            <div className="mb-1">
                                <div className="d-flex justify-content-between">
                                    <Label className="form-label" for="login-password">
                                        Password
                                    </Label>
                                    <Link to="/forgot-password">
                                        <small>Forgot Password?</small>
                                    </Link>
                                </div>
                                <InputPasswordToggle
                                    className="input-group-merge"
                                    id="login-password"
                                    name="password"
                                    inputClassName={`${formData.email === false ? "border-danger" : ""}`}
                                    value={formData.password === false ? "" : formData.password}
                                    onChange={handleInputChange}

                                />
                            </div>
                            <div className="mb-1">
                                <Label className="form-label">
                                    Login as
                                </Label>
                                <Form.Select name="login_as" value={formData.login_as} onChange={handleInputChange}>
                                    <option value={null}></option>
                                    <option value="Admin">Admin</option>
                                    <option value="Staff">Staff</option>
                                </Form.Select>
                            </div>
                            <div className="form-check mb-1">
                                <Input type="checkbox" id="remember-me" />
                                <Label className="form-check-label" for="remember-me">
                                    Remember Me
                            </Label>
                            </div>
                            <Button type="submit" color="primary" block>
                                Sign in
              </Button>
                        </Form>
                        {/* <p className="text-center mt-2">
              <span className="me-25">New on our platform?</span>
              <Link to="/register">
                <span>Create an account</span>
              </Link>
            </p> */}
                        {/* <div className="divider my-2">
              <div className="divider-text">or</div>
            </div> */}
                        {/* <div className="auth-footer-btn d-flex justify-content-center">
              <Button color="facebook">
                <Facebook size={14} />
              </Button>
              <Button color="twitter">
                <Twitter size={14} />
              </Button>
              <Button color="google">
                <Mail size={14} />
              </Button>
              <Button className="me-0" color="github">
                <GitHub size={14} />
              </Button>
            </div> */}
                    </Col>
                </Col>
            </Row>
            <Toaster
            toastOptions={{
                success: {
                style: {
                    background: 'green',
                },
                },
                error: {
                style: {
                    background: 'rgba(245,65,55,1)',
                    color: '#fff',
                    fontFamily: "'IBM Plex Sans', sans-serif"
                },
                },
            }}
        />
        </div>
    );
};

export default Login;
