import React from "react";
import RepairItemsCard from "./RepairItemsCard";
import "./RepairItemsSection.css";
import SectionHeading from "../sectionHeading";
import { Container } from "react-bootstrap";
const RepairItemsSection = () => {
    const repairItemsArray = [
        {
            imageSrc: "apple-iphone-small.jpg",
            alt: "apple-iphone-small",
            text: "Apple Iphones"
        },
        {
            imageSrc: "ipad-small.png",
            alt: "ipad-small",
            text: "Apple Ipads"
        },
        {
            imageSrc: "android-phone-small.jpg",
            alt: "android-phone-small",
            text: "All Android Phones"
        },
        {
            imageSrc: "tablet-small.png",
            alt: "tablet-small",
            text: "All Tablets"
        },
        {
            imageSrc: "nokia-microsoft-small.png",
            alt: "nokia-phone",
            text: "Nokia & Microsoft Phones"
        },
        {
            imageSrc: "laptop-small.png",
            alt: "laptop",
            text: "Laptops"
        },
        {
            imageSrc: "pc-small.jpg",
            alt: "computer",
            text: "Desktop PCs"
        },
        {
            imageSrc: "playstation-small.png",
            alt: "playstation",
            text: "Play Stations"
        }
    ];
  return (
    <section className="our_work_models relative">
      <Container>
        <div className="text-center mb-20">
         
          <SectionHeading text="We repair all the models"/>
        </div>

        <div className="list_of_devices">
          <div className="flex justify-center flex-wrap card_group">
            {
                repairItemsArray.map((item,index)=>{
                    return(
                        <RepairItemsCard key={item.alt} repairItemImage={item.imageSrc} repairItemImageAlt={item.alt} repairItemText={item.text} className={index < 3 ? "sm-visible" : ""}/>
                    )
                })
            }
          </div>
        </div>
      </Container>

      <div style={{textAlign: "center", marginTop: "20px"}}>
          <div className="view_more_btn">
            <a href="/moredeals" className="">More models</a>
          </div>
        </div>
    </section>
  );
};
export default RepairItemsSection;
