// ** React Imports
import React, { useEffect, useState } from "react";
import navLinksArray from '../../Data/AdminNavigation';
// import { Navbar, Nav, NavItem, NavLink } from "reactstrap";
import { useLocation } from 'react-router-dom';

const Sidebar = () => {
    // const [userData, setUserData] = useState(null)
    const userDataString = localStorage.getItem('user');
    const userData = JSON.parse(userDataString);
    // useEffect(() => {
    //     setUserData(JSON.parse(localStorage.getItem('user')))
    // }, [])
    // console.log(userData);
    
    const currentLocation = useLocation();
    
    return (
        <div className="main-menu menu-fixed menu-accordion menu-shadow expanded menu-light">
            <div className="navbar-header">
                <ul className="nav navbar-nav flex-row">
                    <li className="nav-item me-auto">
                        <a aria-current="page" className="navbar-brand active" href="/home">
                            {/* <span className="brand-logo">
                                <img src="/src/assets/images/logo/logo.svg" alt="logo" />
                            </span> */}
                            <h2 className="brand-text mb-0"></h2>
                        </a>
                    </li>
                    <li className="nav-item nav-toggle">
                        <div className="nav-link modern-nav-toggle cursor-pointer">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                data-tour="toggle-icon"
                                className="text-primary toggle-icon d-none d-xl-block"
                            >
                                <circle cx="12" cy="12" r="10"></circle>
                                <circle cx="12" cy="12" r="3"></circle>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="toggle-icon icon-x d-block d-xl-none"
                            >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="shadow-bottom"></div>
            <div className="scrollbar-container main-menu-content ps">
                <ul className="navigation navigation-main">
                    {/* <Navbar>
                        <Nav>
                            <NavItem>
                                <NavLink className={`d-flex align-items-center ${currentLocation.pathname === '/admin/dashboard' ? 'active' : ''}`} href="/admin/dashboard">Home</NavLink>
                            </NavItem>
                            
                        </Nav>
                    </Navbar> */}
                    {
                        navLinksArray.map((item, index)=>{
                            return (item.access === false) ? (
                                <li className="nav-item" key={index}>
                                    <a aria-current="page" className={`d-flex align-items-center ${currentLocation.pathname === item.url ? 'active' : ''}`} href={item.url}>
                                        <span className="menu-item text-truncate">{item.text}</span>
                                    </a>
                                </li>
                            ): item.access === userData.login_as ?(
                                <li className="nav-item" key={index}>
                                    <a aria-current="page" className={`d-flex align-items-center ${currentLocation.pathname === item.url ? 'active' : ''}`} href={item.url}>
                                        <span className="menu-item text-truncate">{item.text}</span>
                                    </a>
                                </li>
                            ): null
                            // return(
                            //     <li className="nav-item" key={index}>
                            //         <a aria-current="page" className={`d-flex align-items-center ${currentLocation.pathname === item.url ? 'active' : ''}`} href={item.url}>
                            //             <span className="menu-item text-truncate">{item.text}</span>
                            //         </a>
                            //     </li>
                            // )
                        })
                    }
                    {/* <li className="nav-item">
                        <a aria-current="page" className="d-flex align-items-center active" href="/home">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                <polyline points="9 22 9 12 15 12 15 22"></polyline>
                            </svg>
                            <span className="menu-item text-truncate">Home</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/second-page">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                <polyline points="22,6 12,13 2,6"></polyline>
                            </svg>
                            <span className="menu-item text-truncate">Deal of the Week</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/second-page">
                            <span className="menu-item text-truncate">Deal of the Week</span>
                        </a>
                    </li> */}
                </ul>
                <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
                    <div className="ps__thumb-x" tabIndex="0" style={{ left: '0px', width: '0px' }}></div>
                </div>
                <div className="ps__rail-y" style={{ top: '0px', right: '1px' }}>
                    <div className="ps__thumb-y" tabIndex="0" style={{ top: '0px', height: '0px' }}></div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
