import React from "react";
import { IconExternalLink } from "@tabler/icons-react";
const ServicesCard = ({title,shortDescription, image, classname}) => {
  return (
    <div className={`card ${classname ? classname : ''}`}>
      <div className="text_area">
        <h3>
          <span className="title relative">{title}</span>
          <span className="icon">
            <IconExternalLink />
          </span>
        </h3>
        <p>{shortDescription}</p>
      </div>

      <div className="image_section">
        <img
          src={`${process.env.REACT_APP_FRONTEND_DATA_URL}/${image}`}
          alt={title}
        />
      </div>
    </div>
  );
};
export default ServicesCard;
