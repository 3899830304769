import React from "react";
import "./Cart.css";
import Header from "./component/Header/header";
import ThreeDParticleScene from "./component/ThreeDParticleScene";
const Cart = ()=>{
    return(
        <div className="App">
            <ThreeDParticleScene/>
            <Header></Header>

        </div>
    )
}

export default Cart