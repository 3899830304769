import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const ThreeDParticleScene = () => {
  const containerRef = useRef(null);
  // const windowHalfX = window.innerWidth / 2;
  // const windowHalfY = window.innerHeight / 2;
  let camera, scene, renderer, material, particles;
  let prevFrameTime = Date.now();

  const init = () => {
    camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 5, 2000);
    camera.position.z = 500;

    scene = new THREE.Scene();
    scene.fog = new THREE.FogExp2(0x0000ff, 0.001);

    const geometry = new THREE.BufferGeometry();
    const vertices = [];
    const size = 2000;

    for (let i = 0; i < 10000; i++) {
      const x = (Math.random() * size + Math.random() * size) / 2 - size / 2;
      const y = (Math.random() * size + Math.random() * size) / 2 - size / 2;
      const z = (Math.random() * size + Math.random() * size) / 2 - size / 2;

      vertices.push(x, y, z);
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

    material = new THREE.PointsMaterial({
      size: 2,
      color: 0xffffff,
      sizeAttenuation: true,
      alphaTest: 0.5,
      transparent: true,
    });

    particles = new THREE.Points(geometry, material);
    scene.add(particles);

    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    containerRef.current.appendChild(renderer.domElement);

    window.addEventListener('resize', onWindowResize);
    onWindowResize();
  };

  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  const animate = () => {
    let currentTime = Date.now();
    let deltaTime = (currentTime - prevFrameTime) * 0.001;

    requestAnimationFrame(animate);
    renderScene();
    update(deltaTime);
    prevFrameTime = currentTime;
  };

  const update = (deltaTime) => {
    // var time = Date.now() * 5e-5;
    particles.rotation.y -= 0.1 * deltaTime;
    particles.rotation.x -= particles.rotation.x * 0.01 * deltaTime;
    // var h = ((360 * (1 + time)) % 360) / 360;
    // particles.material.color.setHSL(h, 0.5, 0.5);
  };

  const renderScene = () => {
    camera.position.x += (100 * 2 - camera.position.x);
    camera.position.y += (10 * 2 - camera.position.y);
    camera.lookAt(scene.position);
    renderer.render(scene, camera);
    // scene.rotation.x += 0.001
    scene.rotation.y += 0.003;
  };

  useEffect(() => {
    init();
    animate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div style={{position: "fixed", zIndex: "-1"}} ref={containerRef} />;
};

export default ThreeDParticleScene;

