import React from "react";
import './productCard.css';
import FullContentLink from "./fullContentLink";
const ProductCard = ({productDetailsUrl, productImageUrl, productName, productCondition, productBrand, productCurrentPrice, productOldPrice, productStatus}) => {
    if(productStatus){
  return (
    <div className="card product_card relative">
      <div className="image_section relative">
        <img
          src={productImageUrl}
          alt="product"
        />
        {
            (productCurrentPrice && productOldPrice) ? (
                <div className="overly_content product_discount_percent">
                    {(((productCurrentPrice - productOldPrice) / productOldPrice) * 100).toFixed(2)} %
                </div>
            ) : null
        }
        
      </div>
      <div className="text_section">
        <h3>{productName}</h3>
        <div className="product_description">
            {
                productCondition ? (
                    <p>Condition : {productCondition}</p>
                ) : null
            }
          <p>Brand : {productBrand}</p>
        </div>
        <div className="flex justify-spece-between align-center flex-wrap">
          <div className="price price--on-sale">
            <div className="sale-price">
              {/* <span className="visually-hidden">Regular price</span> */}
              <span className="product-price__price product-price__sale">
                <span className="product-price__price">
                  <span className="money">
                  £{productCurrentPrice}
                  </span>
                </span>
              </span>
              {
                productOldPrice ? (
                    <s className="product-price__price compare_price">
                        <span className="money">
                        £{productOldPrice}
                        </span>
                    </s>
                ) : null
              }
              
            </div>
          </div>
          <a href={productDetailsUrl} className="link_btn product_details">
            Details
          </a>
        </div>
      </div>
      <FullContentLink productDetailsUrl={productDetailsUrl}/>
    </div>
  );
}
};
export default ProductCard;
