import React, { useState } from "react";
import "./header.css";
import {HeaderMenu} from "./headerMenu";
import OffCanvasMain from "./offCanvasMenu";
import FullContentLink from "../fullContentLink";
const Header = () => {
  // const []
  // const [activeDropdown, setActiveDropdown] = useState(null);

  // const toggleDropdown = (index) => {
  //   setActiveDropdown((prevActive) => (prevActive === index ? null : index));
  // };

  const menu_array = {
    header_menu: [
      {
        key: "home",
        slug: "",
        submenu: false
      },
      {
        key: "Repairing",
        slug: "repairing",
        submenu: false
      },
      {
        key: "sales",
        submenu: [
          {
            key: "iPhones",
            slug: "products/iPhones",
            submenu: false
          },
          {
            key: "iPads",
            slug: "products/iPads",
            submenu: false
          },
          {
            key: "Android Phones",
            slug: "products/Androids",
            // submenu: {
            //   key: "mini submenu 1",
            //   submenu: false
            // }
          },
          {
            key: "tablets",
            slug: "products/tablets",
            submenu: false
          },
        ]
      },
    //   {
    //     key: "Unlocking",
    //     submenu: false
    //   },
      {
        key: "Shop",
        slug: "products",
        submenu: false
      },
      {
        key: "Contact Us",
        slug: "contactus",
        submenu: false
      },
    //   {
    //     key: "Our Services",
    //     submenu: false
    //   },
    ]
  }
  const list_of_menu = menu_array.header_menu;
  return (
    <header className="App-header">
      <div className="container flex justify-spece-between align-center">
        <div className="app_logo_section flex align-center relative">
          <img
            src={`${process.env.REACT_APP_FRONTEND_DATA_URL}/techdoctor_logo.png`}
            className="App-logo"
            alt="logo"
          />
          <span style={{ fontWeight: "bold", color: "cyan" }}> TECH </span>
          <span style={{ fontSize: "25px", color: "#fd9170" }}>DOCTOR</span>
          <FullContentLink productDetailsUrl="/" />
        </div>
        <HeaderMenu headerMenu={list_of_menu}></HeaderMenu>
        <OffCanvasMain headerMenu={list_of_menu}></OffCanvasMain>
        

        {/* <ul className="flex">
          {
            list_of_menu.map((value)=>{
              if(value.submenu !== false){
                return (<li>{value.key}</li>)
              }else{

              }
            })
          }
          <li>Home</li>
          <li>Repairing</li>
          <li>
            <button
              className={`dropdown_btn ${activeDropdown === 1 ? "active" : ""}`}
              onClick={() => toggleDropdown(1)}
            >
              Sales
            </button>
            <ul className={`submenu ${activeDropdown === 1 ? "show" : ""}`}>
              <li>Iphone</li>
              <li>Iphone 2</li>
              <li>
                <button
                  className={`dropdown_btn ${
                    activeDropdown === 2 ? "active" : ""
                  }`}
                  onClick={() => toggleDropdown(2)}
                >
                  sub menus
                </button>
                <ul className={`submenu ${activeDropdown === 2 ? "show" : ""}`}>
                  <li>iterm 1</li>
                  <li>iterm 2</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul> */}
      </div>
    </header>
  );
};
export default Header;
