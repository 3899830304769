import React from "react";
import PropTypes from 'prop-types';
const RepairingProcessCard = ({processImage, processImageAlt, processTitle, processSteps})=>{
    return(
        <div className="card service_description_card" data-aos="fade-right">
        <div className="image_section">
          <img
            src={`${process.env.REACT_APP_FRONTEND_DATA_URL}/${processImage}`}
            alt={processImageAlt}
          />
          
        </div>
        <div className="overly_content">
          <h4>{processTitle}</h4>
        </div>
        
        <div className="text_content text-start">
            {
                processSteps.map((step)=>{
                    return(
                        <p>
                           {step}
                        </p>
                    );
                })
            }
        </div>
      </div>
    )
}
RepairingProcessCard.prototype = {
    processImage: PropTypes.string,
    processImageAlt: PropTypes.string,
    processTitle: PropTypes.string,
    processSteps: PropTypes.array
}
export default RepairingProcessCard