// UserContext.js
import { createContext, useContext, useState } from 'react';

const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [oldUser, setOldUser] = useState(null)
//   const existing_user = localStorage.getItem('user')
//   if(existing_user){
//     setUser(existing_user);
//   }

  const login = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    setUser(null);
  };
  const superUserLogin = (userData) =>{
    setOldUser(userData)
  }
  const superUserLogout = ()=>{
    setOldUser(null)
  }

  return (
    <AdminContext.Provider value={{ user, login, logout, oldUser, superUserLogin, superUserLogout }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useUser = () => useContext(AdminContext);