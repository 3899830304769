import React from 'react'


import { Navigate, Outlet } from 'react-router-dom'
import { useUser } from '../../Context/AdminContext'


const useAuth = () => {
    const user = localStorage.getItem('user')
    const {user: contextUser} = useUser()
    if (user || contextUser) {
        return true
    } else {
        return false
    }
}


const PublicRoutes = (props) => {


    const auth = useAuth()
    console.log(auth);
    console.log("PublicRoutes");
    
    


    return auth ? <Navigate to="/admin/dashboard" /> : <Outlet />
}


export default PublicRoutes;;