const RepairingData = () => {
    return [
        {
            id: 1,
            path: "/smartphone-repairing",
            c_problems: [
                {
                    name: "Front Screen Repairs",
                    imageSrc: "/assets/main_images/broken-screen.png",
                    altText: "Broken Screen",
                },
                {
                    name: "Phone Battery Replacement",
                    imageSrc: "/assets/main_images/battery-replacement.png",
                    altText: "Battery Replacement",
                },
                {
                    name: "On-Off Button Repairs",
                    imageSrc: "/assets/main_images/on-off-button.png",
                    altText: "On Off button replacement",
                },
                {
                    name: "Water Damage Repairs",
                    imageSrc: "/assets/main_images/water-damage-phone.png",
                    altText: "Water Damage Phone",
                },
                {
                    name: "Charging Port Repairs",
                    imageSrc: "../assets/main_images/charging-port-repair.png",
                    altText: "Charging Port Repair",
                },
                {
                    name: "Common Problems Repairs",
                    imageSrc: "/assets/main_images/common-problems-android.png",
                    altText: "Card image cap",
                },
            ],
            repair_info: {
                imageSrc: "/assets/main_images/android-phone-small.jpg",
                imageAlt: "android-phone-image",
                heading: "Home comming 22",
                content: [
                    {
                        heading: "ANDROID PHONE REPAIR",
                        text: `<p>We can carry out repairs on all Android Smart Phone Models. The most common models we are asked about can be found below, though if the repair you require isn’t advertised or the model you are looking to get repaired is not shown, contact us through email or via telephone. We will check our suppliers and see if there are any parts available for your model and, if so, quote you a price and give you a rough time the repair can be completed.</p><p>Like every device we repair here at Revive Lab the part we have repaired or replaced comes with a 3 month warranty (any liquid damage services, as an exception, come with a 1 month warranty). Should anything go wrong with the newly repaired part while you are under a valid warranty bring your device in and we will resolve the issue free of charge.</p>`,
                    },
                    {
                        heading: "Before you go for repair",
                        text: `<p>If it is possible, we recommend that you backup your data from your computer before getting a repair. During repairs we never intentionally wipe any data or access any unnecessary applications but there is a small chance that data loss can occur during any repair. We are not liable for the loss of any data during a repair should it occur. Backing up your data gives you the reassurance that all your data is safe and secure throughout your repair and can be easily restored should anything be lost.</p> <p>When booking your computer in for repair, we may ask you for any lock codes or password you use to access your computer. We ask this for the sole reason of testing your computer after we complete the repair. We pride ourselves on our professional and secure service and would never access any application that we do not need to in order to test your computer. This being said, we understand that some customers are not comfortable giving us this information. If this is the case then we will still carry out the repair but will have to wait until you come to collect your device before we can test it properly. Should any issues become evident, we will have to book the computer in again to resolve the issue.</p>`,
                    },

                ],
                table_data: {
                    heading: "Regular Screen Replacement Price and Time",
                    table: {
                        headings: ["Model/Device", "Problem/Issue", "Estimated Cost", "Normal Repair Time"],
                        rows: [
                            ["Samsung A10", "Screen Broken", "£45", "1 hour"],
                            ["Samsung A20", "Screen Broken / Not working", "£50", "2 hours"],
                            ["Samsung A30", "Screen Broken / Not working", "£50", "1 day"],
                        ],
                    },
                },
                warning: {
                    text: "**For the solution of any problems on your device, please kindly visit our stores or call our desk support.",
                },
            }

        },
        {
            id: 2,
            path: "/smare-repairing",
            c_problems: [
                {
                    name: "Front Screen Repairs",
                    imageSrc: "../assets/main_images/broken-screen.png",
                    altText: "Broken Screen",
                },
                {
                    name: "Phone Battery Replacement",
                    imageSrc: "../assets/main_images/battery-replacement.png",
                    altText: "Battery Replacement",
                },
                {
                    name: "On-Off Button Repairs",
                    imageSrc: "../assets/main_images/on-off-button.png",
                    altText: "On Off button replacement",
                },
                {
                    name: "Water Damage Repairs",
                    imageSrc: "../assets/main_images/water-damage-phone.png",
                    altText: "Water Damage Phone",
                },
                {
                    name: "Charging Port Repairs",
                    imageSrc: "../assets/main_images/charging-port-repair.png",
                    altText: "Charging Port Repair",
                },
                {
                    name: "Common Problems Repairs",
                    imageSrc: "../assets/main_images/common-problems-android.png",
                    altText: "Card image cap",
                },
            ],
            repair_info: {
                imageSrc: "../assets/main_images/android-phone-small.jpg",
                imageAlt: "android-phone-image",
                heading: "Home comming 223",
                content: [
                    {
                        heading: "ANDROID PHONE REPAIR",
                        text: `<p>We can carry out repairs on all Android Smart Phone Models. The most common models we are asked about can be found below, though if the repair you require isn’t advertised or the model you are looking to get repaired is not shown, contact us through email or via telephone. We will check our suppliers and see if there are any parts available for your model and, if so, quote you a price and give you a rough time the repair can be completed.</p><p>Like every device we repair here at Revive Lab the part we have repaired or replaced comes with a 3 month warranty (any liquid damage services, as an exception, come with a 1 month warranty). Should anything go wrong with the newly repaired part while you are under a valid warranty bring your device in and we will resolve the issue free of charge.</p>`,
                    },
                    {
                        heading: "Before you go for repair",
                        text: `<p>If it is possible, we recommend that you backup your data from your computer before getting a repair. During repairs we never intentionally wipe any data or access any unnecessary applications but there is a small chance that data loss can occur during any repair. We are not liable for the loss of any data during a repair should it occur. Backing up your data gives you the reassurance that all your data is safe and secure throughout your repair and can be easily restored should anything be lost.</p> <p>When booking your computer in for repair, we may ask you for any lock codes or password you use to access your computer. We ask this for the sole reason of testing your computer after we complete the repair. We pride ourselves on our professional and secure service and would never access any application that we do not need to in order to test your computer. This being said, we understand that some customers are not comfortable giving us this information. If this is the case then we will still carry out the repair but will have to wait until you come to collect your device before we can test it properly. Should any issues become evident, we will have to book the computer in again to resolve the issue.</p>`,
                    },

                ],
                table_data: {
                    heading: "Regular Screen Replacement Price and Time",
                    table: {
                        headings: ["Model/Device", "Problem/Issue", "Estimated Cost", "Normal Repair Time"],
                        rows: [
                            ["Samsung A10", "Screen Broken", "£45", "1 hour"],
                            ["Samsung A20", "Screen Broken / Not working", "£50", "2 hours"],
                            ["Samsung A30", "Screen Broken / Not working", "£50", "1 day"],
                        ],
                    },
                },
                warning: {
                    text: "**For the solution of any problems on your device, please kindly visit our stores or call our desk support.",
                },
            }

        }
    ]

}
export default RepairingData;