import React from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SingleProduct } from '../Apis/SingleProduct';
import AddProductForm from './AdminComponent/AddProductForm';
import { ProductEdit } from './AdminApis/DataApi';

export default function MainProductEdit() {
    const { id } = useParams();
    const {
        data,
        isLoading,
        isError,
    } = useQuery("singleProductData", () => SingleProduct(id));
    if (isLoading) {
        return <h1>Loading..</h1>
    }
    if (isError) {
        <h1>Error</h1>
    }
    if (data) {
        const defultData = data.data[0]
        return (
            <div className='App'>
                <AddProductForm actionFunction={ProductEdit} initialFormData={defultData} onSuccessPagePath="/admin/dashboard" />
            </div>
        )
    }

}
