import React from "react";
import { Form } from "react-bootstrap";
import { useCheckoutContext } from "../Context/CheckoutContext";

const DeliveryMethodFormElement = () => {
  const {selectedShippingMethod, setSelectedShippingMethod} = useCheckoutContext()

  const handleShippingMethodChange = (event) => {
    setSelectedShippingMethod(event.target.value);
  };
  // console.log(selectedShippingMethod);

  return (
    <div className="input_elements">
      <Form.Check
        type="radio"
        name="shipping_method"
        value="flat"
        id="home-delivery-radio"
        label="Home Delivery - 60৳"
        checked={selectedShippingMethod === "flat"}
        onChange={handleShippingMethodChange}
      />
      <Form.Check
        type="radio"
        name="shipping_method"
        value="pickup"
        id="store-pickup-radio"
        label="Store Pickup - 0৳"
        checked={selectedShippingMethod === "pickup"}
        onChange={handleShippingMethodChange}
      />
      <Form.Check
        type="radio"
        name="shipping_method"
        value="express"
        id="express-radio"
        label="Request Express - Charge Applicable"
        checked={selectedShippingMethod === "express"}
        onChange={handleShippingMethodChange}
      />
    </div>
  );
};

export default DeliveryMethodFormElement;
