import React from 'react';
import AddProductForm from './AdminComponent/AddProductForm';
import { AddNewDeal } from './AdminApis/DataApi';
const AddDeals = ()=>{
    const formFields = {
        deal_id: null,
        product_name: "",
        storage_capacity: 0,
        product_condition: "New",
        Product_color: "",
        product_network: "",
        product_mrp: 0,
        product_price: 0,
        product_image: "",
        image_alternate: "",
        short_description: "",
        full_description: "",
        weekly_deal: 0,
        product_status: 1,
        url_heading: "",
        meta_description: "",
        metatag: ""
    };
    
    return(
        <div className="App">
            <AddProductForm initialFormData={formFields} actionFunction={AddNewDeal} onSuccessPagePath="/admin/deals" />
        </div>
    )
}
export default AddDeals



