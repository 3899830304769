import React from 'react';
import AddProductForm from './AdminComponent/AddProductForm';
import { AddStoresData } from './AdminApis/DataApi';
const AddStores = () => {
    const formFields = {
        store_name: "",
        store_address: "",
        store_phone: "",
        store_email: "",
        store_mobile: "",
        whatsapp_number: "",
        map_link: ""
    };
    
    return (
        <div className="App">
            <AddProductForm actionFunction={AddStoresData} initialFormData={formFields} onSuccessPagePath="/admin/stores" />
        </div>
    )
}
export default AddStores;



