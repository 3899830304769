import React from "react";
import ProductCard from "./productCard";
import { Container, Row } from "react-bootstrap";
const RelatedProduct = ({ productList }) => {
  return (
    <Container>
      <Row>
        {productList.data.map((item) => {
          return (
            <ProductCard
              key={item.id}
              productName={item.p_name}
              productBrand={item.p_brand}
              productCondition={item.p_condition}
              productCurrentPrice={item.price}
              productOldPrice={item.price < item.mrp ? item.mrp : null}
              productDetailsUrl={`/products/${item.p_category}/${item.id}-${item.url_heading}`}
              productStatus={item.p_status}
              productImageUrl={`${process.env.REACT_APP_FRONTEND_DATA_URL}/${item.p_image}`}
            />
          );
        })}
      </Row>
    </Container>
  );
};
export default RelatedProduct;
